import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { pxToRem } from 'utils/pxToRem';
import { Colors, FontFamily, FontSize, FontWeight, Padding } from 'style/types';

export interface CollapseActionStyleProps {
  isOpen?: boolean;
  isLocked?: boolean;
}

export const CollapseAction = styled.div<CollapseActionStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Primary};
  border-radius: 50%;
  width: ${pxToRem(26)};
  height: ${pxToRem(26)};
  margin-right: ${Padding.XS};
  transform: rotate(0);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);

  svg {
    height: ${pxToRem(16)};

    &.chevron {
      transform: translateX(1px);
    }

    path {
      fill: ${Colors.White};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(90deg);
    `}

  > section {
    overflow: hidden;
  }
`;

export const CollapseHeader = styled.header`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.Black90};
  height: ${pxToRem(40)};
  padding: 0 ${Padding.Default};
  position: sticky;
  top: 0;
  z-index: 1;

  + header {
    margin-top: 0.5rem;
  }
`;

export const CollapseHeaderLabel = styled.span`
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.SemiBold};
  font-family: ${FontFamily.Heading};
  text-transform: uppercase;
  letter-spacing: 1.13px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CollapseContainer = styled(motion.section)`
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
`;

export const CollapseContent = styled(motion.div)`
  padding: 1rem;
  transform-origin: top center;
`;
