import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomCardPrizePool } from 'atoms/Cards/RoomCard';
import { ContentArea } from 'atoms/ContentArea';
import { DescriptionListItem } from 'atoms/DescriptionListItem';
import { Divider } from 'atoms/Divider';
import { DollarCentsAndTickets } from 'atoms/DollarCentsAndTickets';
import { HtmlContent } from 'atoms/HtmlContent';
import { Paragraph } from 'atoms/Paragraph';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { GroupPrizeTable } from 'molecules/GroupPrizeTable';
import { fetchRoomTypeDetails } from 'store/roomTypes/actions';
import { RoomTypeType } from 'services/pttv/api/rooms/types';
import { EntryFee, RoomInfoContainer, RoomInfoDescriptionList } from './RoomInfo.styled';

interface Props {
  roomTypeId: string;
}

export const RoomInfo: React.FC<Props> = ({ roomTypeId }) => {
  const roomType = useAppSelector(getRoomTypeById(roomTypeId));
  const { t } = useTranslation('RoomInformation');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRoomTypeDetails(roomType.roomTypeId));
  }, []);

  const {
    description,
    shortDescription,
    termsAndConditions,
    minCapacity,
    dollarCentsBuyIn,
    ticketBuyIn,
    dollarCentsPrizePool,
    dollarCentsPayoutModel,
    ticketPayoutModel,
    type,
  } = roomType;

  const hasPrizes = (dollarCentsPrizePool && dollarCentsPayoutModel) || ticketPayoutModel;

  const getTypeTranslation = () => {
    switch (type) {
      case RoomTypeType.NORMAL:
        return t('types.normal');
      case RoomTypeType.GLOBAL:
        return t('types.global');
      case RoomTypeType.H2H:
        return t('types.h2h');
      default:
        return '';
    }
  };

  return (
    <RoomInfoContainer>
      <Divider noMargin>{t('sections.roomInformation')}</Divider>
      <ContentArea>
        <Paragraph>{description || shortDescription}</Paragraph>
      </ContentArea>

      <RoomInfoDescriptionList>
        <DescriptionListItem title={t('labels.type')}>{getTypeTranslation()}</DescriptionListItem>
        <DescriptionListItem title={t('labels.minAmountPlayers')}>
          {minCapacity}
        </DescriptionListItem>
        <DescriptionListItem title={t('labels.prizes')} boldDescription={false}>
          <RoomCardPrizePool noMargin roomType={roomType} noLabel />
        </DescriptionListItem>
        <DescriptionListItem title={t('labels.entryFee')}>
          <EntryFee>
            <DollarCentsAndTickets dollarCents={dollarCentsBuyIn || 0} tickets={!!ticketBuyIn} />
          </EntryFee>
        </DescriptionListItem>
      </RoomInfoDescriptionList>

      {hasPrizes && (
        <>
          <Divider>{t('sections.payoutTable')}</Divider>
          <GroupPrizeTable roomTypeId={roomTypeId} />
        </>
      )}

      {termsAndConditions && (
        <>
          <Divider>{t('sections.finePrint')}</Divider>
          <ContentArea>
            <HtmlContent dangerousContent={termsAndConditions} />
          </ContentArea>
        </>
      )}
    </RoomInfoContainer>
  );
};
