import styled, { css } from 'styled-components';
import { BorderWidth, Colors } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  active: boolean;
}

export const Circle = styled.button.attrs<Props>({
  type: 'button',
})<Props>`
  align-items: center;
  border: ${BorderWidth.Large} solid ${Colors.TextSub};
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  height: ${pxToRem(32)};
  width: ${pxToRem(32)};
  transition: all 0.3s ease;

  > svg {
    color: ${Colors.TextSub};
    height: ${pxToRem(16)};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${Colors.Primary};
      border-color: ${Colors.Primary};

      > svg {
        color: ${Colors.Text};
      }
    `}
`;
