import { createSelector } from 'reselect';
import i18n from 'i18next';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { getLeagueByName } from 'store/leagues/selectors';
import { CurriedSelector, RootState } from 'store/types';
import { getUser } from 'store/user/selectors';
import { ChatMessage, ChatMessageList, LastChatMessage, LeagueChatState } from './types';
import { getChatAvatarUrl, getChatMessageType, getChatSenderName, isMuted } from './utils';

const getUserMeta = (state: RootState) => state.userMeta;

export const getMessages = (state: RootState): LeagueChatState => state.leagueChat || {};

export const getMessagesByLeague = (leagueName: string): CurriedSelector<ChatMessage[]> =>
  createSelector(
    getLeagueByName(leagueName),
    getMessages,
    getUserMeta,
    (league, messages, userMeta) => {
      if (!league) {
        return [];
      }
      return (messages[leagueName] || []).filter((message) => !isMuted(message, userMeta));
    },
  );

export const getChatMessageListByLeague = (
  leagueName: string,
): CurriedSelector<ChatMessageList[]> =>
  createSelector(
    getLeagueByName(leagueName),
    getMessagesByLeague(leagueName),
    getUser,
    (league, messages, user) => {
      if (!league) {
        return [];
      }

      return messages.reduce<ChatMessageList[]>((acc, message) => {
        if (
          acc[acc.length - 1]?.senderId === message.senderId ||
          (acc[acc.length - 1]?.senderId === 'system' && message.systemMessage)
        ) {
          acc[acc.length - 1].messages.push({
            id: message.id,
            text: message.text,
            isNew: message.isNew,
          });
          return acc;
        }
        const member = league.members.find((sender) => sender.userId === message.senderId);
        const messageType = getChatMessageType(message, user.userId);
        acc.push({
          senderId: messageType === ChatBubbleTypes.Winview ? 'system' : message.senderId,
          senderName: getChatSenderName(member?.displayName, messageType),
          avatarUrl: getChatAvatarUrl(member?.avatarUrl, messageType),
          messages: [
            {
              id: message.id,
              text: message.text,
              isNew: message.isNew,
            },
          ],
          type: messageType,
        });
        return acc;
      }, []);
    },
  );

export const getUnseenMessagesCount = (leagueName: string): CurriedSelector<number> =>
  createSelector(
    getLeagueByName(leagueName),
    getMessagesByLeague(leagueName),
    (league, messages) => {
      if (!league) {
        return 0;
      }

      return messages.filter((message) => !message.seen).length;
    },
  );

export const getLastChatMessage = (leagueName: string): CurriedSelector<LastChatMessage | null> =>
  createSelector(
    getLeagueByName(leagueName),
    getMessagesByLeague(leagueName),
    getUser,
    (league, messages, user) => {
      const message = messages.pop();
      if (!league) {
        return null;
      }

      if (!message) {
        return {
          senderId: '',
          senderName: getChatSenderName(undefined, ChatBubbleTypes.Winview),
          avatarUrl: getChatAvatarUrl(undefined, ChatBubbleTypes.Winview),
          message: i18n.t('LeagueDetail:chatModal.defaultChatMessage'),
          type: ChatBubbleTypes.Winview,
        };
      }

      const member = league.members.find((sender) => sender.userId === message.senderId);
      const messageType = getChatMessageType(message, user.userId);

      return {
        senderId: message.senderId,
        senderName: getChatSenderName(member?.displayName, messageType),
        avatarUrl: getChatAvatarUrl(member?.avatarUrl, messageType),
        message: message.text,
        type: messageType,
      };
    },
  );
