import styled, { css } from 'styled-components';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { ChatSender } from 'atoms/Chat/Sender';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { BorderWidth, Colors, Gap } from 'style/types';

export const ChatBubbleList = styled.div`
  width: 80%;
`;

interface Props {
  type: ChatBubbleTypes;
}

export const ChatRowContainer = styled.section<Props>`
  align-items: end;
  display: grid;
  gap: 0.5rem 1rem;
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: repeat(${({ type }) => (type === ChatBubbleTypes.Right ? '1' : '2')}, auto);
  grid-template-areas:
    '. sender'
    'avatar messages';

  ${ChatBubbleList} {
    grid-area: messages;
    justify-self: ${({ type }) => (type === ChatBubbleTypes.Right ? 'end' : 'start')};
  }

  ${ChatSender} {
    grid-area: sender;
  }

  ${StyledAvatar} {
    ${({ type }) =>
      type === ChatBubbleTypes.Winview &&
      css`
        border: ${BorderWidth.Large} solid ${Colors.Primary};
      `}
    grid-area: avatar;
  }

  + section {
    margin-top: ${Gap.L};
  }
`;
