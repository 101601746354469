import styled from 'styled-components';
import { ContentArea } from 'atoms/ContentArea';

export const ChatModalContentArea = styled.section`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

export const ChatModalContent = styled(ContentArea)`
  grid-area: 1/1/3/2;
  overflow: scroll;
  padding-bottom: 100px;

  scroll-behavior: smooth;
`;

export const ChatModalFooter = styled(ContentArea)`
  backdrop-filter: blur(1.5px);
  background-color: hsla(0, 0%, 0%, 0.8);
  border-top: 1px solid gray;
  grid-area: 2/1/3/2;
  z-index: 1;
`;
