import styled from 'styled-components';
import React from 'react';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';
import { pxToRem } from 'utils/pxToRem';
import { ProTipContainer } from 'molecules/ProTip/ProTip.styled';

export const SettingsFormModal = styled.article`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};
  flex: 1;
  transform: translateY(-${pxToRem(68)});

  ${ProTipContainer} {
    margin-top: 1rem;
  }
`;

interface CurrentValueProps {
  label: string;
}

export const CurrentValue = styled.header.attrs<CurrentValueProps>(({ label, children }) => ({
  children: (
    <>
      <div>{label}</div>
      {children}
    </>
  ),
}))<CurrentValueProps>`
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black90};
  color: ${Colors.Secondary};
  font-weight: ${FontWeight.SemiBold};
  padding: ${Padding.XXL};
  word-break: break-word;

  div {
    color: ${Colors.Black30};
    font-size: ${FontSize.MS};
  }
`;

export const SettingsForm = styled.form`
  padding: ${Padding.XXL};

  ${ButtonBarContainer} {
    margin-top: ${Gap.XL};
  }
`;

interface ModalContentAreaProps {
  minHeight?: string;
}

export const ModalContentArea = styled.section<ModalContentAreaProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: ${({ minHeight }) => minHeight || 'initial'};
  padding: ${Padding.Default};
`;

export const SettingsContent = styled(SettingsFormModal)`
  padding: ${Padding.XXL};

  ${ButtonBarContainer} {
    margin-top: ${Gap.XL};
  }
`;

interface ModalMessageProps {
  value: string;
}

export const ModalMessage = styled.section.attrs<ModalMessageProps>(({ value, children }) => ({
  children: (
    <>
      {children}
      <div>{value}</div>
    </>
  ),
}))<ModalMessageProps>`
  font-weight: ${FontWeight.SemiBold};
  text-align: center;

  div {
    color: ${Colors.Secondary};
    margin-top: ${Gap.L};
  }
`;
