import styled from 'styled-components';
import React from 'react';
import EyeIcon from 'assets/icons/EyeIcon.svg';
import EyeOffIcon from 'assets/icons/EyeOffIcon.svg';
import { Colors } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const PasswordWrapper = styled.section`
  position: relative;
`;

interface ButtonProps {
  $visible: boolean;
}

export const ToggleVisibility = styled.button.attrs<ButtonProps>(({ $visible }) => ({
  type: 'button',
  children: $visible ? <EyeOffIcon /> : <EyeIcon />,
}))<ButtonProps>`
  background-color: transparent;
  border: none;
  color: ${Colors.ActiveBorder};
  height: ${pxToRem(32)};
  padding: 0;
  position: absolute;
  right: ${pxToRem(16)};
  top: ${pxToRem(14)};
  width: ${pxToRem(32)};

  > svg {
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
  }
`;
