import styled from 'styled-components';
import { Colors, FontWeight } from 'style/types';
import { PropositionListItem } from 'store/propositions/types';
import { PropositionState } from 'services/pttv/api/constants';

interface InputProps {
  proposition: PropositionListItem;
}

interface AttrsProps {
  fontWeight: FontWeight;
  color: Colors;
}

export const PropositionCardContent = styled.section.attrs<InputProps, AttrsProps>(
  ({ proposition: { state, hasBet } }) => ({
    color: !hasBet && state === PropositionState.OPEN ? Colors.Text : Colors.TextSub,
    fontWeight:
      !hasBet && state === PropositionState.OPEN ? FontWeight.SemiBold : FontWeight.Regular,
  }),
)<InputProps>`
  grid-area: content;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
`;
