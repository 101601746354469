import React from 'react';
import { useAppSelector } from 'store/hooks';
import { getUserByContestIdAndRoomTypeId } from 'store/contestInfo/selectors';
import { getLeaderboardUserMovement } from 'store/leaderboardMovement/selectors';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { RoomPlayer } from 'services/pttv/api/rooms/types';
import { LeaderboardUser } from './LeaderboardUser';

interface Props {
  roomTypeId: string;
  contestId: string;
}

export const RoomTypeLeaderboardUser: React.FC<Props> = ({ roomTypeId, contestId }) => {
  const user = useAppSelector(getUserByContestIdAndRoomTypeId(contestId, roomTypeId));
  const roomType = useAppSelector(getRoomTypeById(roomTypeId));
  const movement = useAppSelector(getLeaderboardUserMovement(contestId, roomTypeId));

  if (!user) {
    return null;
  }

  return <LeaderboardUser user={user as RoomPlayer} movement={movement} title={roomType.name} />;
};
