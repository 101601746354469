import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import CrossIcon from 'assets/icons/CrossIcon.svg';
import BulbIcon from 'assets/icons/BulbIcon.svg';

import { ClientData } from 'services/pttv/api/users/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getClientData } from 'store/user/selectors';
import { updateClientDataStore } from 'store/user/actions';
import { CloseButton, ProTipContainer, ProTipHeader, ProTipTitle } from './ProTip.styled';

interface ProTipProps {
  title?: string;
  showToggle?: boolean;
  clientData?: ClientData;
}
export const ProTip: React.FC<ProTipProps> = ({
  title = 'PRO TIP:',
  children,
  showToggle = true,
  clientData,
}) => {
  const hideProTip = useAppSelector(getClientData(clientData as ClientData, false));
  const dispatch = useAppDispatch();
  const [active, setActive] = useState(true);
  const proTipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (clientData && !!hideProTip) {
      setActive(false);
    }
  }, [hideProTip, clientData]);

  const handleCloseButton = () => {
    const rect = proTipRef.current?.getBoundingClientRect();
    if (rect?.height && proTipRef.current) {
      proTipRef.current.style.height = `${rect.height}px`;
    }
    if (clientData) {
      dispatch(
        updateClientDataStore({
          [ClientData.ContestProTipHidden]: true,
        }),
      );
    }
    setActive(false);
  };

  return (
    <AnimatePresence initial={false}>
      {active && (
        <ProTipContainer
          ref={proTipRef}
          exit={{ opacity: 0, height: 0, padding: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <ProTipHeader>
            <ProTipTitle>
              <BulbIcon />
              {title}
            </ProTipTitle>
            {showToggle && (
              <CloseButton onClick={handleCloseButton}>
                <CrossIcon />
              </CloseButton>
            )}
          </ProTipHeader>
          {children}
        </ProTipContainer>
      )}
    </AnimatePresence>
  );
};
