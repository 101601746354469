import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getSelectableContestsByGame,
  getFirstOpenContestIndexByGame,
} from 'store/contests/selectors';
import { getSportByGameId } from 'store/sports/selectors';
import { isGamePreGame } from 'store/games/selectors';
import { JoinRoomFooter } from 'molecules/JoinRoomFooter';
import { GameHeader } from 'molecules/GameHeader';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { Collapse } from 'atoms/Collapse';
import { formatOrdinals } from 'utils/formatters';
import { JoinRoomCardList } from 'organisms/JoinRoomCardList';
import { isLiveOrRegistering } from 'store/contests/contestStateHelper';
import { joinContestsAndRooms } from 'store/rooms/actions';
import { OverlayHeader } from 'molecules/OverlayHeader';
import {
  getDetailedSelectionInvestment,
  getFlatRoomSelection,
} from 'store/roomSelection/selectors';
import wrapperLib from 'services/wrapper';
import { useDialog } from 'hooks/useDialog';
import { debugLog } from 'utils/log';
import { getUserDollarCents } from 'store/user/selectors';
import { clearRoomTypeSelection } from 'store/roomSelection/actions';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { useMessages } from 'hooks/useMessages';
import { PttvError, PttvErrorCode } from 'services/pttv/types';
import { LocationErrorMessage } from 'molecules/LocationErrorMessage';
import { LocationError } from 'services/wrapper/types';
import { wait } from 'utils/wait';
import { HtmlContent } from 'atoms/HtmlContent';
import { HelpPages } from 'store/clientProperties/types';
import { CollapseContainer, JoinRoomWrapper, RoomTypeList } from './JoinRoom.styled';
import { JoinRoomConfirmContent } from './JoinRoomConfirmContent';

interface Props {
  gameId: string;
}

type Coords = {
  latitude: number | null;
  longitude: number | null;
  accuracy: number | null;
};

export const JoinRoom: React.FC<Props> = ({ gameId }) => {
  const contests = useAppSelector(getSelectableContestsByGame(gameId));
  const firstOpenContest = useAppSelector(getFirstOpenContestIndexByGame(gameId));
  const sport = useAppSelector(getSportByGameId(gameId));
  const isPreGame = useAppSelector(isGamePreGame(gameId));
  const investment = useAppSelector(getDetailedSelectionInvestment);
  const selectedRoomTypes = useAppSelector(getFlatRoomSelection);
  const isUserKYCInfoCollected = useAppSelector((state) => state.user.clientLogicState?.kycInfo);
  const isUserKYCInfoPassed = useAppSelector((state) => state.user.clientLogicState?.kycPass);
  const userDollarCents = useAppSelector(getUserDollarCents);
  const { errorMessage } = useMessages();
  const dispatch = useAppDispatch();
  const { showConfirm, showAlert } = useDialog();
  const { replace, push, goBack } = useHistory();
  const { t } = useTranslation('RoomSelection');

  const joinErrorAction = (error?: Partial<PttvError>) => {
    showConfirm(
      t('failedDialog.title'),
      <HtmlContent dangerousContent={errorMessage(error as PttvError)} />,
      {
        confirmButtonAction: () => {
          dispatch(clearRoomTypeSelection());
          push('/profile/kyc');
        },
        confirmButtonText: 'Confirm',
      },
    );
  };

  const joinConfirmAction = async () => {
    let coordinates: Coords = { latitude: null, longitude: null, accuracy: null };
    const contestId = selectedRoomTypes.length > 0 ? selectedRoomTypes[0].contestId : null;
    const isRealMoneyBuyIn =
      !!investment.roomTypeTotalDollarCents || !!investment.roomTypeTotalTickets.length;

    if (isRealMoneyBuyIn) {
      if (!isUserKYCInfoCollected && !isUserKYCInfoPassed) {
        // TODO: Remove !isUserKYCInfoPassed once Cash Bet has fixed their KYC override
        await wait(200); // Wait for other dialogs close to prevent the error dialog to be closed during opening.
        joinErrorAction({ code: PttvErrorCode.ERROR_FROM_CASHBET_9 });
        return;
      }

      try {
        await wrapperLib.isLocationServiceEnabled();
        const { coords } = await wrapperLib.getCurrentCoordinates();
        coordinates = {
          longitude: coords.longitude,
          latitude: coords.latitude,
          accuracy: coords.accuracy,
        };
      } catch (e) {
        showAlert(t('locationError.title'), <LocationErrorMessage error={e as LocationError} />);
        return;
      }
    }

    const response = await dispatch(joinContestsAndRooms(coordinates));
    if ((response.payload as PttvError).code) {
      const error = response.payload as PttvError;
      debugLog('[JoinRoom]', 'Error join room', error);
      showAlert(t('paymentError.title'), errorMessage(error));
    } else {
      replace(`/games/${gameId}/contests/${contestId}`);
    }
  };

  // TODO: This is only needed if we'll implement PWA.
  // const joinConfirmActionAndEnsureClientVersion = async () => {
  // const isVersionChanged = await checkIsVersionChanged();
  // if (isVersionChanged) {
  //   dispatch(updateReady(true));
  //   return;
  // }
  // joinConfirmAction();
  // };

  const goToDepositPage = () => {
    push('/profile/deposit');
  };

  const handleJoinRoomClick = async () => {
    const hasEnoughDollars = investment.roomTypeTotalDollarCents <= userDollarCents;

    if (hasEnoughDollars) {
      await showConfirm(t('confirmDialog.hasEnoughDollars.title'), <JoinRoomConfirmContent />, {
        confirmButtonAction: () => {
          joinConfirmAction();
        },
      });
    } else {
      await showConfirm(
        t('confirmDialog.notEnoughDollars.title'),
        t('confirmDialog.notEnoughDollars.description'),
        {
          closeButtonText: 'Cancel',
          confirmButtonText: "Let's buy",
          confirmButtonAction: goToDepositPage,
        },
      );
    }
  };

  const handleJoinRoomClose = () => {
    dispatch(clearRoomTypeSelection());
    goBack();
  };

  return (
    <JoinRoomWrapper>
      <OverlayHeader
        type={OverlayHeaderActions.Cross}
        onClose={handleJoinRoomClose}
        helpPageId={HelpPages.HELP_PAGE_JOIN_CONTEST_URL}
      >
        {t('header.title')}
      </OverlayHeader>
      <GameHeader gameId={gameId} />
      {isPreGame ? (
        <RoomTypeList>
          <JoinRoomCardList contest={contests[0]} />
        </RoomTypeList>
      ) : (
        <CollapseContainer>
          {contests.map((contest, index) => (
            <Collapse
              key={contest.contestId}
              label={`${formatOrdinals(contest.period)} ${sport?.period}`}
              isLocked={!isLiveOrRegistering(contest.state)}
              open={firstOpenContest === index}
            >
              {isLiveOrRegistering(contest.state) && <JoinRoomCardList contest={contest} />}
            </Collapse>
          ))}
        </CollapseContainer>
      )}
      <JoinRoomFooter onClick={handleJoinRoomClick} />
    </JoinRoomWrapper>
  );
};
