import React, { ChangeEvent, PropsWithChildren, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FieldGroup } from 'atoms/Form/FieldGroup';
import { Input, Label, ErrorMessage } from 'atoms/Form';
import { PasswordWrapper, ToggleVisibility } from './PasswordInput.styled';

interface Props<T> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  disabled?: boolean;
  format?: (value: string) => string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PasswordInput = <T extends Record<string, any>>({
  name,
  control,
  placeholder = '',
  label,
  defaultValue,
  rules,
  disabled = false,
  format,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const toggleInputType = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (format) {
      inputValue = format(inputValue);
    }
    onChange(inputValue);
  };

  return (
    <FieldGroup>
      <Label htmlFor={fieldName}>{label}</Label>
      <PasswordWrapper>
        <Input
          type={inputType}
          placeholder={placeholder}
          onChange={handleInputChange} // send value to hook form
          onBlur={onBlur} // notify when input is touched/blur
          value={value} // input value
          name={fieldName} // send down the input name
          ref={ref}
          disabled={disabled}
          hasError={!!error}
        />
        <ToggleVisibility onClick={toggleInputType} $visible={inputType === 'text'} />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </PasswordWrapper>
    </FieldGroup>
  );
};
