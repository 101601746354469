import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { UserMetaState } from 'store/userMeta/types';
import chatLogoUrl from 'assets/images/chat-logo.png';
import { ChatMessage } from './types';

export const isMuted = ({ senderId, timestamp }: ChatMessage, userMeta: UserMetaState): boolean =>
  userMeta.mutedUsers[senderId]?.mutedAt
    ? timestamp >= (userMeta.mutedUsers[senderId].mutedAt as number)
    : false;

export const getChatMessageType = (message: ChatMessage, userId: string): ChatBubbleTypes => {
  if (message.systemMessage) {
    return ChatBubbleTypes.Winview;
  }
  if (message.senderId === userId) {
    return ChatBubbleTypes.Right;
  }
  return ChatBubbleTypes.Left;
};

export const getChatAvatarUrl = (avatarUrl = '', type: ChatBubbleTypes): string => {
  if (type === ChatBubbleTypes.Winview) {
    return chatLogoUrl;
  }
  return avatarUrl;
};

export const getChatSenderName = (senderName = '', type: ChatBubbleTypes): string => {
  if (type === ChatBubbleTypes.Winview) {
    return 'WinView';
  }
  return senderName;
};
