import React from 'react';
import { Description, DescriptionListItemContainer, Title } from './DescriptionListItem.styled';

interface DescriptionListItemProps {
  title: string;
  boldDescription?: boolean;
}

export const DescriptionListItem: React.FC<DescriptionListItemProps> = ({
  title,
  children,
  boldDescription = true,
}) => (
  <DescriptionListItemContainer>
    <Title>{title}</Title>
    <Description bold={boldDescription}>{children}</Description>
  </DescriptionListItemContainer>
);
