import React, { ChangeEvent, KeyboardEvent, PropsWithChildren } from 'react';
import {
  Path,
  PathValue,
  UnpackNestedValue,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { FieldGroup } from 'atoms/Form/FieldGroup';
import { Input, Label, ErrorMessage } from 'atoms/Form';

interface Props<T> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  disabled?: boolean;
  format?: (value: string) => string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextInput = <T extends Record<string, any>>({
  name,
  control,
  placeholder = '',
  label,
  defaultValue,
  rules,
  disabled = false,
  format,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const { setValue } = useFormContext<T>();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (format) {
      inputValue = format(inputValue);
    }
    onChange(inputValue);
  };

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value.trim() as UnpackNestedValue<PathValue<T, Path<T>>>);
    onBlur();
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setValue(
        name,
        ((event?.target as HTMLInputElement).value || '').trim() as UnpackNestedValue<
          PathValue<T, Path<T>>
        >,
      );
    }
  };

  return (
    <FieldGroup>
      <Label htmlFor={fieldName}>{label}</Label>
      <div>
        <Input
          type="text"
          placeholder={placeholder}
          onChange={handleInputChange} // send value to hook form
          onBlur={handleOnBlur} // notify when input is touched/blur
          onKeyPress={handleKeyPress}
          value={value} // input value
          name={fieldName} // send down the input name
          ref={ref}
          disabled={disabled}
          hasError={!!error}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </FieldGroup>
  );
};
