import React from 'react';
import { Table, TableHeadCell, TableRow } from 'atoms/Table';
import { RoomLeaderboardListItem } from 'molecules/LeaderboardListItem';
import {
  getContestHistoryRoomPlayers,
  getContestHistoryRoomUser,
} from 'store/contestHistory/selectors';
import { useAppSelector } from 'store/hooks';
import { getRoomPlayers, getRoomUser } from 'store/rooms/selectors';

interface Props {
  roomId: string;
  contestId: string;
  isContestHistory?: boolean;
}

export const RoomLeaderboardList: React.FC<Props> = ({ roomId, contestId, isContestHistory }) => {
  const players = useAppSelector(
    isContestHistory ? getContestHistoryRoomPlayers(contestId, roomId) : getRoomPlayers(roomId),
  );

  const user = useAppSelector(
    isContestHistory ? getContestHistoryRoomUser(contestId, roomId) : getRoomUser(roomId),
  );

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeadCell width="12%" align="center">
            Rank
          </TableHeadCell>
          <TableHeadCell>Player</TableHeadCell>
          <TableHeadCell width="15%">Points</TableHeadCell>
          <TableHeadCell width="18%">Payout</TableHeadCell>
        </TableRow>
      </thead>
      <tbody>
        {players.map((player) => (
          <RoomLeaderboardListItem
            key={player.userId}
            player={player}
            isUser={player.userId === user?.userId}
            contestId={contestId}
          />
        ))}
      </tbody>
    </Table>
  );
};
