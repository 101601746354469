import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentArea } from 'atoms/ContentArea';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { SearchInput } from 'molecules/Form/SearchInput';
import { OverlayHeader } from 'molecules/OverlayHeader';
import { ShareInvite } from 'molecules/ShareInvite';
import { UsersAPI } from 'services/pttv/api';
import { SearchResultPlayer } from 'molecules/SearchResultPlayer';
import { FoundUser } from 'services/pttv/api/users/types';
import { useAppSelector } from 'store/hooks';
import { InviteLeagueCard } from 'molecules/LeagueCards/InviteLeagueCard';
import { PttvError } from 'services/pttv/types';
import { useDialog } from 'hooks/useDialog';
import { League } from 'store/leagues/types';
import { Friend } from 'services/pttv/api/friends/types';
import { getPopulatedUrl } from 'store/clientProperties/selectors';
import { ClientProps } from 'store/clientProperties/types';
import { InviteFriendsWrapper, InviteTitle, SearchForm } from './InviteNewFriends.styled';

interface FormFields {
  search: string;
}

const defaultValues: FormFields = {
  search: '',
};

interface Props {
  addFriendCallback: (userId: string) => Promise<League | Friend | PttvError>;
  leagueName?: string;
  shareInvite?: boolean;
}

export const InviteNewFriend: React.FC<Props> = ({
  addFriendCallback,
  leagueName,
  shareInvite,
}) => {
  const methods = useForm<FormFields>();
  const shareUrl = useAppSelector(getPopulatedUrl(ClientProps.SHARE_FRIEND_INVITE_URL));
  const { showAlert } = useDialog();
  const { goBack } = useHistory();
  const { t } = useTranslation('InviteFriend');
  const [friend, setFriend] = useState<FoundUser | null>(null);
  const [showResult, setShowResult] = useState(false);

  const searchQuery = methods.watch('search');
  useEffect(() => {
    setShowResult(false);
  }, [searchQuery]);

  const handleSearchSubmit = async ({ search }: FormFields) => {
    try {
      document.querySelector<HTMLInputElement>('[name="search"]')?.blur();
      const foundUser = await UsersAPI.searchUser(search);
      setFriend(foundUser);
    } catch (e) {
      setFriend(null);
    }
    setShowResult(true);
  };

  const handleAddFriendClick = async () => {
    if (friend?.userId) {
      const response = await addFriendCallback(friend.userId);
      methods.reset(defaultValues);

      if (!(response as PttvError).code) {
        goBack();
      }
      return;
    }

    showAlert(
      t('inviteNewFriend.noFriendModal.title'),
      t('inviteNewFriend.noFriendModal.description'),
    );
  };

  const handleInviteFriendClose = () => {
    goBack();
  };

  return (
    <InviteFriendsWrapper hasLeague={!!leagueName} shareInvite={!!shareInvite}>
      <OverlayHeader type={OverlayHeaderActions.Chevron} onClose={handleInviteFriendClose}>
        {t('inviteNewFriend.title')}
      </OverlayHeader>
      {!!leagueName && <InviteLeagueCard leagueName={leagueName} />}
      <InviteTitle>{t('searchfield.title')}</InviteTitle>
      <FormProvider {...methods}>
        <SearchForm onSubmit={methods.handleSubmit(handleSearchSubmit)}>
          <SearchInput
            name="search"
            control={methods.control}
            placeholder={t('searchfield.placeholder')}
          />
        </SearchForm>
      </FormProvider>
      <ContentArea>
        <SearchResultPlayer
          showResult={showResult}
          avatarUrl={friend?.avatarUrl}
          displayName={friend?.displayName || ''}
          callback={handleAddFriendClick}
        />
      </ContentArea>
      {shareInvite && (
        <ContentArea>
          <ShareInvite shareUrl={shareUrl} />
        </ContentArea>
      )}
    </InviteFriendsWrapper>
  );
};
