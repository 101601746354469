import styled from 'styled-components';
import { Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { RoomCard } from 'atoms/Cards/RoomCard';
import { CollapseContent } from 'atoms/Collapse/Collapse.styled';
import { pxToRem } from 'utils/pxToRem';

export const JoinRoomWrapper = styled.section`
  background-color: ${Colors.Black};
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: scroll;
`;

export const RoomTypeList = styled.section`
  overflow: scroll;
  padding: ${Padding.Default};

  ${RoomCard} + ${RoomCard} {
    margin-top: ${Gap.Default};
  }
`;

export const CollapseContainer = styled.section`
  overflow: scroll;

  ${CollapseContent} {
    ${RoomCard} + ${RoomCard} {
      margin-top: ${Gap.Default};
    }
  }
`;

export const JoinRoomConfirmContentContainer = styled.section`
  h2 {
    font-size: ${FontSize.XXL};
    font-family: ${FontFamily.Heading};
    font-weight: ${FontWeight.SemiBold};
    margin-bottom: ${Gap.ML};
  }
`;

export const JoinRoomFees = styled.div`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XXL};
  font-weight: ${FontWeight.SemiBold};
  color: ${Colors.Secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Padding.Default};

  svg {
    height: ${pxToRem(32)};
    margin-right: ${Padding.XXS};
  }
`;
