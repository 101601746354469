import styled from 'styled-components';
import { TicketCardType } from 'molecules/TicketCard/types';
import { BorderRadius, Colors, Gap, Padding } from 'style/types';

interface Props {
  type: TicketCardType;
}

export const TicketCard = styled.article.attrs({
  role: 'button',
})<Props>`
  background-color: ${({ type }) =>
    type === TicketCardType.Active ? Colors.ActiveBackground : Colors.Black90};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.White};
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'title title quantity'
    'info info expire';
  gap: ${Gap.Default};
  padding: ${Padding.Default};

  + article {
    margin-top: ${Gap.Default};
  }
`;
