import styled from 'styled-components';
import { Colors, FontSize, FontWeight, Padding } from 'style/types';

export const RoomInfoContainer = styled.section`
  height: 100%;
`;

export const RoomInfoDescriptionList = styled.section`
  margin-bottom: ${Padding.XL};
`;

export const EntryFee = styled.section`
  color: ${Colors.Secondary};
  font-size: ${FontSize.XL};
  font-weight: ${FontWeight.Regular};
`;
