import React, { useState, MouseEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { ModalPage } from 'atoms/ModalPage';
import { useAppSelector } from 'store/hooks';
import { getLeagueAvatars } from 'store/leagueAvatars/selectors';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { ButtonBar } from 'atoms/ButtonBar';
import { AvatarList, AvatarRadio, ModalContent } from './LeagueAvatarSelect.styled';

interface Props {
  title: string;
  onSelect: (avatarId: string) => void;
  activeAvatarId: string;
}

export const AvatarSelectModal: React.FC<Props> = ({ title, onSelect, activeAvatarId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(activeAvatarId);
  const avatars = useAppSelector(getLeagueAvatars);
  const { t } = useTranslation('LeagueForm');

  const handleInfoButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  };

  const handleSelectClick = () => {
    onSelect(selectedAvatar);
    setIsOpen(false);
  };

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAvatar(event.target.value);
  };

  const handleAvatarClick = (event: MouseEvent<HTMLInputElement>) => {
    if (event.detail === 2 && !!selectedAvatar) {
      onSelect(selectedAvatar);
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleInfoButtonClick}
        size={ButtonSize.Tiny}
        scheme={ButtonSchemes.Secondary}
      >
        {t('selectAvatarModal.changeButton')}
      </Button>

      <ModalPage title={title} isOpen={isOpen} onClose={handleCloseButtonClick}>
        <ModalContent>
          <AvatarList>
            {Object.values(avatars).map((avatar) => (
              <AvatarRadio key={avatar.avatarId}>
                <input
                  type="radio"
                  name="avatarUrl"
                  id={avatar.avatarId}
                  value={avatar.avatarId}
                  checked={avatar.avatarId === selectedAvatar}
                  onChange={handleAvatarChange}
                  onClick={handleAvatarClick}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={avatar.avatarId}>
                  <Avatar src={avatar.url} size={AvatarSize.XXXL} />
                </label>
              </AvatarRadio>
            ))}
          </AvatarList>
          <ButtonBar>
            <Button onClick={handleSelectClick}>{t('selectAvatarModal.selectButton')}</Button>
          </ButtonBar>
        </ModalContent>
      </ModalPage>
    </>
  );
};
