import React from 'react';
import { formatOrdinals, formatNumber } from 'utils/formatters';
import { Avatar } from 'atoms/Avatar';
import { Movement } from 'atoms/Movement';
import { DollarCentsAndTickets } from 'atoms/DollarCentsAndTickets';
import { AvatarSize } from 'atoms/Avatar/types';
import { RoomPlayer } from 'services/pttv/api/rooms/types';
import { LeaderboardMovements } from 'store/leaderboardMovement/types';
import {
  FieldLabel,
  FieldValue,
  FieldWrapper,
  ImageWrapper,
  LeaderBoardUserContainer,
  LeaderboardUserFooter,
  LeaderBoardUserTitle,
  Payout,
} from '../LeaderboardUser.styled';

interface Props {
  user: RoomPlayer;
  title: string;
  movement: LeaderboardMovements;
}

export const LeaderboardUser: React.FC<Props> = ({ user, title, movement }) => (
  <LeaderBoardUserContainer>
    <LeaderBoardUserTitle>{title}</LeaderBoardUserTitle>
    <FieldWrapper>
      <FieldLabel>Rank</FieldLabel>
      <FieldValue>
        {formatOrdinals(user.rank)}
        <Movement direction={movement} />
      </FieldValue>
    </FieldWrapper>
    <ImageWrapper>
      <Avatar size={AvatarSize.XXXL} src={user.avatarUrl} />
    </ImageWrapper>
    <FieldWrapper>
      <FieldLabel>Score</FieldLabel>
      <FieldValue>{formatNumber(user.points)}</FieldValue>
    </FieldWrapper>

    <LeaderboardUserFooter>
      Payout:
      <Payout>
        <DollarCentsAndTickets dollarCents={user.dollarCentsWon} tickets={!!user.ticketWon} />
      </Payout>
    </LeaderboardUserFooter>
  </LeaderBoardUserContainer>
);
