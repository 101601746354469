import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';
import ChevronIcon from 'assets/icons/chevron.svg';
import StopwatchIcon from 'assets/icons/StopwatchIcon.svg';
import { pxToRem } from 'utils/pxToRem';
import { PropositionListItem } from 'store/propositions/types';
import { PropositionState } from 'services/pttv/api/constants';
import { Colors } from 'style/types';

const propositionIcon = ({ state }: PropositionListItem) => {
  switch (state) {
    case PropositionState.LOCKED:
      return <StopwatchIcon />;

    case PropositionState.RESOLVED:
      return null;

    default:
      return <ChevronIcon />;
  }
};

const propositionIconHeight = ({ state }: PropositionListItem) => {
  switch (state) {
    case PropositionState.LOCKED:
      return pxToRem(20);

    default:
      return pxToRem(16);
  }
};

const propositionIconColor = ({ state }: PropositionListItem) => {
  switch (state) {
    case PropositionState.LOCKED:
      return Colors.Black40;

    default:
      return Colors.TextSub;
  }
};

interface InputProps {
  proposition: PropositionListItem;
}

interface AttrsProps {
  iconHeight: string;
  iconColor: Colors;
}

export const PropositionCardAction = styled(Link)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['iconHeight', 'iconColor'].includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<InputProps, AttrsProps>(({ proposition }) => ({
    children: propositionIcon(proposition),
    iconHeight: propositionIconHeight(proposition),
    iconColor: propositionIconColor(proposition),
  }))<InputProps>`
  align-self: center;
  align-items: center;
  color: ${Colors.TextSub};
  display: flex;
  grid-area: action;

  > svg {
    color: ${({ iconColor }) => iconColor};
    height: ${({ iconHeight }) => iconHeight};
  }
`;
