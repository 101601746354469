import styled from 'styled-components';
import React from 'react';
import { BorderRadius, Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';
import LinkIcon from 'assets/icons/LinkIcon.svg';

export const ShareInviteWrapper = styled.section`
  background-color: ${Colors.Black90};
  border-radius: ${BorderRadius.Default};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'title title' 'url button';
  grid-template-rows: auto auto;
  gap: ${Gap.Default};
  padding: ${Padding.Default};

  button {
    grid-area: button;
  }
`;

export const ShareInviteTitle = styled.h3`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.Medium};
  grid-area: title;
  letter-spacing: 0.8px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`;

export const ShareInviteUrl = styled.div`
  background-color: ${Colors.Black};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.Black70};
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  grid-area: url;
  padding: ${Padding.XS} ${Padding.XS} ${Padding.XS} ${Padding.XXXL};
  position: relative;

  > input {
    background-color: transparent;
    border: none;
    color: ${Colors.Text};
    display: block;
    height: 100%;
    left: 2rem;
    outline: none;
    position: absolute;
    top: 0;
    width: calc(100% - 2rem);

    &::selection {
      background-color: ${Colors.Secondary};
    }
  }
`;

export const ClipboardUrlButton = styled.button.attrs({
  type: 'button',
  children: <LinkIcon />,
})`
  align-items: center;
  background-color: transparent;
  border: none;
  bottom: 0;
  color: ${Colors.Secondary};
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;

  transition: color 0.3s ease;
  width: 2rem;

  &:hover {
    color: ${Colors.SecondaryDark};
  }

  &:active {
    color: ${Colors.SecondaryLight};
  }

  > svg {
    width: 1rem;
  }
`;
