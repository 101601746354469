import React from 'react';
import { useTranslation } from 'react-i18next';
import TicketIcon from 'assets/icons/TicketsIcon.svg';
import { Button } from 'atoms/Button';
import { formatCurrency } from 'utils/formatters';
import { useAppSelector } from 'store/hooks';
import {
  getTotalRoomTypesTickets,
  getTotalRoomTypesDollarCentsCost,
  getSelectedRoomsCount,
} from 'store/roomSelection/selectors';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { AnimatedCounter } from 'atoms/AnimatedCounter';
import { Cost, CostContainer, FooterWrapper } from './JoinRoomFooter.styled';

interface Props {
  onClick: () => void;
}

export const JoinRoomFooter: React.FC<Props> = ({ onClick }) => {
  const dollarCents = useAppSelector(getTotalRoomTypesDollarCentsCost);
  const tickets = useAppSelector(getTotalRoomTypesTickets);
  const selectedRoomsCount = useAppSelector(getSelectedRoomsCount);
  const { t } = useTranslation('RoomSelection');

  return (
    <FooterWrapper>
      <Button
        scheme={ButtonSchemes.Secondary}
        onClick={onClick}
        disabled={selectedRoomsCount === 0}
      >
        {t('footer.buttonText')} {selectedRoomsCount > 0 ? ` (${selectedRoomsCount})` : ''}
      </Button>
      <CostContainer>
        {t('footer.costTitle')}
        <Cost>
          <AnimatedCounter
            countTo={dollarCents}
            formatter={(amount) => formatCurrency(amount / 100)}
            stepSize={10}
          />
        </Cost>
        {!!tickets && (
          <Cost>
            <TicketIcon />
          </Cost>
        )}
      </CostContainer>
    </FooterWrapper>
  );
};
