import React from 'react';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize, Gap } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface FieldProps {
  orientation: 'column' | 'row';
}

export const RoomTypeFieldWrapper = styled.section.attrs({
  className: 'roomtype-field',
})<FieldProps>`
  align-items: ${({ orientation }) => (orientation === 'row' ? 'center' : 'start')};
  display: flex;
  flex-direction: ${({ orientation }) => orientation};
  font-family: ${FontFamily.Heading};
  justify-content: ${({ orientation }) => (orientation === 'row' ? 'space-between' : 'start')};

  + .roomtype-field {
    margin-left: ${pxToRem(24)};
  }
`;

const Key = styled.span`
  color: ${Colors.TextSub};
  font-size: ${FontSize.M};
  margin-right: ${Gap.S};
  text-transform: uppercase;
`;

interface ValueProps {
  fontSize: FontSize;
}
const Value = styled.span<ValueProps>`
  align-items: center;
  color: ${Colors.Secondary};
  display: flex;
  font-size: ${({ fontSize }) => fontSize};
  gap: ${Gap.XS};

  svg {
    max-width: ${pxToRem(16)};
    max-height: ${pxToRem(16)};
  }

  * + * {
    margin-left: ${Gap.Default};
  }
`;

interface Props {
  label: string;
  valueSize?: FontSize;
  orientation?: 'column' | 'row';
}

export const RoomTypeField: React.FC<Props> = ({ children, label, valueSize, orientation }) => (
  <RoomTypeFieldWrapper orientation={orientation || 'row'}>
    <Key>{label}</Key>
    <Value fontSize={valueSize || FontSize.L}>{children}</Value>
  </RoomTypeFieldWrapper>
);
