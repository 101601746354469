import styled from 'styled-components';

import { ContentArea } from 'atoms/ContentArea';
import { Button } from 'atoms/Button';
import { BorderWidth, Colors } from 'style/types';

export const RoomInfoModalContentArea = styled.section`
  background-color: ${Colors.Black};
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

export const RoomInfoModalContent = styled(ContentArea)`
  grid-area: 1/1/3/2;
  overflow: scroll;
  padding-bottom: 100px;
`;

export const RoomInfoModalFooter = styled(ContentArea)`
  border-top: ${BorderWidth.Default} solid ${Colors.Black90};
  display: flex;
  grid-area: 2/1/3/2;
  justify-content: center;
  z-index: 1;

  ${Button} {
    padding: 0.5rem 5rem;
  }
`;
