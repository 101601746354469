import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RoomCard,
  RoomCardContent,
  RoomCardAside,
  RoomCardHeader,
  RoomCardPrizePool,
  RoomCardPlayerCount,
  RoomCardPromoRibbon,
  RoomCardBanner,
} from 'atoms/Cards/RoomCard';
import { CheckMark } from 'atoms/CheckMark';
import { RoomInfoModal } from 'organisms/RoomInfoModal';
import { useAppSelector } from 'store/hooks';
import { isRoomTypeLocked, isRoomTypeSelected } from 'store/roomSelection/selectors';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { BuyInText } from 'molecules/BuyInText';
import { isUserGuest, userHasTicket } from 'store/user/selectors';
import { useDialog } from 'hooks/useDialog';
import TicketIcon from 'assets/icons/TicketsIcon.svg';
import { EmailFormModal } from 'organisms/Forms/Modals/EmailFormModal';
import { getContestById } from 'store/contests/selectors';

interface Props {
  onSelect: () => void;
  roomTypeId: string;
  contestId: string;
}

export const JoinRoomCard: React.FC<Props> = ({ roomTypeId, contestId, onSelect }) => {
  const roomType = useAppSelector(getRoomTypeById(roomTypeId));
  const isSelected = useAppSelector(isRoomTypeSelected({ contestId, roomTypeId }));
  const contest = useAppSelector(getContestById(contestId));
  const isLocked = useAppSelector(isRoomTypeLocked(contestId, roomTypeId));
  const showTicketBanner = useAppSelector(userHasTicket(roomType.ticketBuyIn || ''));
  const isGuest = useAppSelector(isUserGuest);
  const [isRoomInfoOpen, setIsRoomInfoOpen] = useState(false);
  const [showEmailFormModal, setShowEmailFormModal] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);
  const { showConfirm } = useDialog();
  const { t } = useTranslation('RoomSelection');

  const handleRoomClick = () => {
    if (isLocked) {
      if (isGuest) {
        showConfirm(t('guestUserDialog.title'), t('guestUserDialog.description'), {
          closeButtonText: t('guestUserDialog.closeButton'),
          confirmButtonText: t('guestUserDialog.confirmButton'),
          confirmButtonAction: () => {
            setShowEmailFormModal(true);
          },
        });
        return;
      }
      setIsRoomInfoOpen(true);
      return;
    }
    onSelect();
  };

  useEffect(() => {
    if (roomType?.leaderboardEnabled) {
      setIsGlobal(true);
    }
  }, [roomType]);

  const getPlayerCountLabel = () =>
    isGlobal ? t('playerCountLabel.global') : t('playerCountLabel.regular');

  const getPlayerCount = () => {
    if (isGlobal) {
      const roomTypeCountRoomType = contest.roomTypeCounts.find(
        (roomTypeCount) => roomTypeCount.roomTypeId === roomTypeId,
      );
      return roomTypeCountRoomType?.count || 0;
    }
    return roomType.capacity;
  };

  return (
    <>
      <RoomCard onClick={handleRoomClick}>
        <RoomCardHeader
          isLocked={isLocked}
          isGlobal={isGlobal}
          action={
            <>
              <BuyInText roomTypeId={roomTypeId} />
              <CheckMark checked={isSelected} locked={isLocked} size="1.75rem" />
            </>
          }
        >
          {roomType.name}
        </RoomCardHeader>
        {showTicketBanner && (
          <RoomCardBanner>
            <TicketIcon height=".875rem" /> {t('joinRoomCard.hasTicketsBanner')}
          </RoomCardBanner>
        )}
        {roomType.isPromoRoom && <RoomCardPromoRibbon>Featured</RoomCardPromoRibbon>}
        <RoomCardContent isLocked={isLocked}>
          <RoomCardPrizePool roomType={roomType} />
          {roomType.shortDescription}
        </RoomCardContent>
        <RoomCardAside isLocked={isLocked}>
          <RoomInfoModal
            roomTypeId={roomType.roomTypeId}
            title={roomType.name}
            onSelect={onSelect}
            isSelected={isSelected}
            isOpen={isRoomInfoOpen}
            setIsOpen={setIsRoomInfoOpen}
          />
          <RoomCardPlayerCount label={getPlayerCountLabel()} count={getPlayerCount()} />
        </RoomCardAside>
      </RoomCard>

      <EmailFormModal isOpen={showEmailFormModal} onClose={() => setShowEmailFormModal(false)} />
    </>
  );
};
