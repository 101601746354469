import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tickets } from 'atoms/Tickets';
import { Padding } from 'style/types';
import { formatCurrency } from 'utils/formatters';
import { RoomType } from 'services/pttv/api/rooms/types';
import { RoomTypeField } from './RoomTypeField';

interface PrizePoolProps {
  noMargin?: boolean;
}

export const PrizePool = styled.section<PrizePoolProps>`
  display: flex;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : Padding.XXS)};
`;

interface Props {
  roomType: RoomType;
  noMargin?: boolean;
  noLabel?: boolean;
}

export const RoomCardPrizePool: React.FC<Props> = ({ roomType, noMargin, noLabel }) => {
  const { t } = useTranslation('Cards');
  const { dollarCentsPayoutModel, ticketPayoutModel, dollarCentsPrizePool } = roomType;

  const hasDollarPrizes = () => !!dollarCentsPayoutModel && !!dollarCentsPrizePool;
  const hasTicketPrizes = () => !!ticketPayoutModel;
  const hasNoPrizes = () => !hasDollarPrizes() && !hasTicketPrizes();
  const prizesLabel = noLabel ? '' : t('roomCard.prizesLabel');

  return (
    <PrizePool noMargin={noMargin}>
      {(hasDollarPrizes() || hasTicketPrizes()) && (
        <RoomTypeField label={prizesLabel}>
          {hasDollarPrizes() && <span>{formatCurrency((dollarCentsPrizePool || 0) / 100)}</span>}
          {hasTicketPrizes() && (
            <Tickets>{Object.values(ticketPayoutModel?.ticketPerRank || {}).length}</Tickets>
          )}
        </RoomTypeField>
      )}
      {hasNoPrizes() && <RoomTypeField label={prizesLabel}>None</RoomTypeField>}
    </PrizePool>
  );
};
