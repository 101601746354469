import styled from 'styled-components';
import { ParagraphStyles } from 'atoms/Paragraph';
import { BorderWidth, Colors, FontWeight, Padding } from 'style/types';

const borderStyle = `
  ${BorderWidth.Default} solid ${Colors.Black75};
`;

export const DescriptionListItemContainer = styled.dl`
  align-items: center;
  border-bottom: ${borderStyle};
  display: grid;
  grid-template-columns: auto 1fr;
  line-height: 1;
  margin: 0;
  padding: ${Padding.L} ${Padding.S};

  gap: ${Padding.S};

  &:first-child {
    border-top: ${borderStyle};
  }
`;

export const Title = styled.dt`
  ${ParagraphStyles};
`;

interface DescriptionProps {
  bold?: boolean;
}

export const Description = styled.dd<DescriptionProps>`
  align-items: center;
  display: flex;
  font-weight: ${({ bold }) => (bold ? FontWeight.Bold : FontWeight.Regular)};
  margin: 0;
`;
