import styled, { css } from 'styled-components';
import { LeaderboardMovements } from 'store/leaderboardMovement/types';
import { Colors } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  direction: LeaderboardMovements;
}

export const StyledMovement = styled.div<Props>`
  line-height: 0;
  width: ${pxToRem(12)};

  ${({ direction }) => {
    switch (direction) {
      case LeaderboardMovements.Rising:
        return css`
          color: ${Colors.Success};
        `;
      case LeaderboardMovements.Descending:
        return css`
          color: ${Colors.Error};
          transform: rotate(180deg);
        `;
      default:
        return null;
    }
  }}
`;
