import styled, { css } from 'styled-components';
import { BorderRadius, FontSize, FontFamily, Colors, FontWeight } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { PropositionListItem } from 'store/propositions/types';
import { AnswerCode, PropositionState } from 'services/pttv/api/constants';

interface InputProps {
  proposition: PropositionListItem;
  isAnswerCorrect: boolean;
}

const getIndexColors = (
  { state, hasBet, correctAnswerCode }: PropositionListItem,
  isAnswerCorrect: boolean,
) => {
  const resolvedWithBet = state === PropositionState.RESOLVED && hasBet;
  if (resolvedWithBet && correctAnswerCode === AnswerCode.UNDECIDED) {
    return css`
      background-color: ${Colors.Black50};
      color: hsla(0, 0%, 16%, 0.6);
      font-weight: ${FontWeight.SemiBold};
    `;
  }
  if (resolvedWithBet && isAnswerCorrect) {
    return css`
      background-color: ${Colors.Success};
      color: hsla(0, 0%, 16%, 0.6);
      font-weight: ${FontWeight.SemiBold};
    `;
  }
  if (resolvedWithBet && !isAnswerCorrect) {
    return css`
      background-color: ${Colors.Error};
      color: hsla(0, 0%, 16%, 0.6);
      font-weight: ${FontWeight.SemiBold};
    `;
  }
  return css`
    background-color: hsla(0, 100%, 100%, 0.15);
  `;
};

export const PropositionCardIndex = styled.div<InputProps>`
  ${({ proposition, isAnswerCorrect }) => getIndexColors(proposition, isAnswerCorrect)}
  border-radius: ${BorderRadius.Default};
  font-size: ${FontSize.L};
  font-family: ${FontFamily.Heading};
  grid-area: index;
  height: ${pxToRem(32)};
  line-height: ${pxToRem(30)};
  margin: ${pxToRem(6)} 0 0;
  text-align: center;
  width: ${pxToRem(32)};
`;
