import React, { MouseEvent } from 'react';
import NotificationIcon from 'assets/icons/NotificationIcon.svg';
import { Circle } from './NotificationBell.styled';

interface Props {
  active: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const NotificationBell: React.FC<Props> = ({ active, onClick }) => (
  <Circle active={active} onClick={onClick}>
    <NotificationIcon />
  </Circle>
);
