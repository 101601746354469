import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { Padding } from 'style/types';
import ChevronIcon from 'assets/icons/chevron.svg';
import LockIcon from 'assets/icons/LockIcon.svg';
import { GameCardType } from 'molecules/GameCard/types';
import { Game } from 'store/games/types';
import { NotificationBell } from 'atoms/NotificationBell';
import { useAppDispatch } from 'store/hooks';
import { toggleGameNotification } from 'store/games/actions';
import { useMessages } from 'hooks/useMessages';

const GameCardActionWrapper = styled.div`
  align-self: center;
  grid-area: action;
  padding-left: ${Padding.S};

  > svg {
    height: 1.25rem;
  }
`;

interface Props {
  isLocked?: boolean;
  game: Game;
  cardType: GameCardType;
}

export const GameCardAction: React.FC<Props> = ({ isLocked, game, cardType }) => {
  const dispatch = useAppDispatch();
  const { errorMessage, successMessage } = useMessages();
  const showChevron = () =>
    !isLocked && cardType !== GameCardType.Pending && cardType !== GameCardType.Upcoming;

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(
      toggleGameNotification({
        request: game.gameId,
        errorMessage,
        successMessage,
      }),
    );
  };

  return (
    <GameCardActionWrapper>
      {cardType === GameCardType.Upcoming && (
        <NotificationBell active={game.subscribed} onClick={onClick} />
      )}
      {isLocked && <LockIcon />}
      {showChevron() && <ChevronIcon />}
    </GameCardActionWrapper>
  );
};
