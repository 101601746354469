import styled from 'styled-components';
import { ChatMessageSubmit } from 'atoms/Chat/MessageSubmit';
import { pxToRem } from 'utils/pxToRem';

export const StyledMessageForm = styled.form`
  position: relative;

  > ${ChatMessageSubmit} {
    position: absolute;
    right: ${pxToRem(5)};
    top: ${pxToRem(5)};
  }
`;
