import React, { PropsWithChildren } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FieldGroup } from 'atoms/Form/FieldGroup';
import { TextArea, Label, ErrorMessage } from 'atoms/Form';

interface Props<T> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextAreaInput = <T extends Record<string, any>>({
  name,
  control,
  placeholder = '',
  label,
  defaultValue,
  rules,
  disabled = false,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <FieldGroup>
      <Label htmlFor={fieldName}>{label}</Label>
      <div>
        <TextArea
          placeholder={placeholder}
          onChange={onChange} // send value to hook form
          onBlur={onBlur} // notify when input is touched/blur
          value={value} // input value
          name={fieldName} // send down the input name
          ref={ref}
          disabled={disabled}
          hasError={!!error}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </FieldGroup>
  );
};
