import React from 'react';
import { Avatar } from 'atoms/Avatar';
import { ChatSender } from 'atoms/Chat/Sender';
import { ChatBubble } from 'atoms/Chat/Bubble';
import { AvatarSize } from 'atoms/Avatar/types';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { RoomChatMessageList } from 'store/roomChat/types';
import { ChatBubbleList, ChatRowContainer } from './ChatRow.styled';

type Props = Omit<RoomChatMessageList, 'senderId'>;

export const ChatRow: React.FC<Props> = ({ type, senderName, messages, avatarUrl }) => (
  <ChatRowContainer type={type}>
    {type !== ChatBubbleTypes.Right && (
      <>
        <Avatar src={avatarUrl} size={AvatarSize.MS} />
        <ChatSender type={type}>{senderName}</ChatSender>
      </>
    )}
    <ChatBubbleList>
      {messages.map((message) => (
        <ChatBubble key={message.id} type={type} isNew={message.isNew}>
          {message.text}
        </ChatBubble>
      ))}
    </ChatBubbleList>
  </ChatRowContainer>
);
