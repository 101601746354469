import styled from 'styled-components';

export const CashBetStyle = styled.div`
  -webkit-overflow-scrolling: touch;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const CashBetIframeStyle = styled.iframe`
  background-color: transparent;

  /* TODO: Use apply for reusabe --fit */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
