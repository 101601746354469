import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DollarCentsAndTickets } from 'atoms/DollarCentsAndTickets';
import { TableCell } from 'atoms/Table';
import { RoomPlayer } from 'services/pttv/api/rooms/types';
import { Avatar } from 'atoms/Avatar';
import { formatNumber } from 'utils/formatters';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSportByContestId } from 'store/sports/selectors';
import { IconNumberBadge } from 'atoms/Badge/IconNumberBadge';
import { AvatarSize } from 'atoms/Avatar/types';
import { FriendModal } from 'organisms/FriendRequestModal/FriendModal';
import { requestFetchPlayer } from 'store/players/actions';
import { LeaderboardCell, LeaderboardRow, PlayerCell } from '../LeaderboardListItem.styled';

interface Props {
  player: RoomPlayer;
  isUser: boolean;
  contestId: string;
}

export const RoomLeaderboardListItem: React.FC<Props> = ({ player, isUser, contestId }) => {
  const sport = useAppSelector(getSportByContestId(contestId));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation('Leaderboard');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestFetchPlayer(player.userId));
  }, []);

  const showFriendInfo = () => {
    setIsModalOpen(true);
  };

  const hideFriendInfo = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <LeaderboardRow isUser={isUser} onClick={showFriendInfo}>
        <LeaderboardCell align="center" isUser={isUser}>
          {player.rank}
        </LeaderboardCell>
        <TableCell>
          <PlayerCell>
            <Avatar src={player.avatarUrl} size={AvatarSize.S} />
            {sport && (
              <IconNumberBadge
                iconSrc={sport.avatarUrl}
                number={player.sportExperienceRank as number}
              />
            )}

            {player.displayName}
          </PlayerCell>
        </TableCell>
        <TableCell>{formatNumber(player.points)}</TableCell>
        <TableCell>
          <DollarCentsAndTickets dollarCents={player.dollarCentsWon} tickets={!!player.ticketWon} />
        </TableCell>
      </LeaderboardRow>
      <FriendModal
        userId={player.userId}
        title={t('playerModal.title')}
        isOpen={isModalOpen}
        handleCloseButtonClick={hideFriendInfo}
        handleSelectButtonClick={hideFriendInfo}
        reportPlayerCallback={hideFriendInfo}
      />
    </>
  );
};
