import React from 'react';
import styled from 'styled-components';
import { Padding } from 'style/types';
import ChevronIcon from 'assets/icons/chevron.svg';
import LockIcon from 'assets/icons/LockIcon.svg';

const RoomCardActionWrapper = styled.div`
  align-self: center;
  grid-area: action;
  padding-left: ${Padding.S};

  > svg {
    height: 1.25rem;
  }
`;

interface Props {
  isLocked?: boolean;
  showAction?: boolean;
}

export const RoomCardAction: React.FC<Props> = ({ isLocked, showAction = true }) => (
  <RoomCardActionWrapper>
    {isLocked && <LockIcon />}
    {showAction && !isLocked && <ChevronIcon />}
  </RoomCardActionWrapper>
);
