import React from 'react';
import { DollarCentsAndTickets } from 'atoms/DollarCentsAndTickets';
import { Table, TableCell, TableHeadCell, TableRow } from 'atoms/Table';
import { useAppSelector } from 'store/hooks';
import { getGroupPrizesByRoomTypeId } from 'store/roomTypes/selectors';
import { Winnings } from './GroupPrizeTable.styled';

interface Props {
  roomTypeId: string;
}

export const GroupPrizeTable: React.FC<Props> = ({ roomTypeId }) => {
  const groupPrizes = useAppSelector(getGroupPrizesByRoomTypeId(roomTypeId));
  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeadCell width="20%" align="center">
            Rank
          </TableHeadCell>
          <TableHeadCell width="80%">Winnings</TableHeadCell>
        </TableRow>
      </thead>
      <tbody>
        {groupPrizes.map(({ dollarCents, rank, ticket }) => (
          <TableRow key={`${rank}-${dollarCents || 0}-${ticket || ''}`}>
            <TableCell align="center">{rank}</TableCell>

            <TableCell>
              <Winnings>
                <DollarCentsAndTickets
                  dollarCents={dollarCents}
                  tickets={!!ticket}
                  dollarCentsFractionDigits={2}
                />
                {ticket && ticket}
              </Winnings>
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};
