import styled from 'styled-components';
import { FontSize, Padding } from 'style/types';

interface Props {
  isLocked?: boolean;
  noRightPadding?: boolean;
}

export const RoomCardContent = styled.section<Props>`
  font-size: ${FontSize.MS};
  grid-area: content;
  opacity: ${({ isLocked }) => (isLocked ? 0.35 : 1)};
  padding: ${Padding.Default};
  position: relative;
`;
