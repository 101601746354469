import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { PropositionListItem } from 'store/propositions/types';
import { AnswerCode, PropositionState } from 'services/pttv/api/constants';
import { Bet } from 'store/bets/types';
import { ParagraphStyles } from 'atoms/Paragraph';

const getBetColor = (
  { state }: PropositionListItem,
  showResult?: boolean,
  isAnswerCorrect?: boolean,
): Colors => {
  switch (state) {
    case PropositionState.OPEN:
      return Colors.Secondary;

    case PropositionState.RESOLVED:
      return showResult && !isAnswerCorrect ? Colors.Error : Colors.Text;

    default:
      return Colors.Text;
  }
};

interface InputProps {
  proposition: PropositionListItem;
  showResult?: boolean;
  isAnswerCorrect?: boolean;
}

interface AttrsProps {
  betColor: Colors;
}

const StyledPrediction = styled.section`
  grid-area: prediction;
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  padding-top: ${Gap.Default};
`;

const BetDescription = styled.span.attrs<InputProps, AttrsProps>(
  ({ proposition, showResult, isAnswerCorrect }) => ({
    betColor: getBetColor(proposition, showResult, isAnswerCorrect),
  }),
)<InputProps>`
  color: ${({ betColor }) => betColor};
`;

const CorrectAnswer = styled.div`
  ${ParagraphStyles};
  margin-top: ${Padding.XXS};

  > span {
    color: ${Colors.Text};
  }
`;

interface Props {
  proposition: PropositionListItem;
  bet: Bet;
}

export const PropositionCardPrediction: React.FC<Props> = ({ proposition, bet }) => {
  const { t } = useTranslation('Cards');
  const isAnswerCorrect = () =>
    proposition.correctAnswerCode === bet?.answerCode ||
    proposition.correctAnswerCode === AnswerCode.UNDECIDED;

  const correctAwnserMultiplier = () =>
    proposition.answers.find((answer) => answer.code === proposition.correctAnswerCode)
      ?.pointsMultiplier || 0;

  return (
    <StyledPrediction>
      {proposition.state === PropositionState.OPEN && bet && t('propositionCard.predictionLabel')}
      {!!proposition.betDescription && (
        <BetDescription proposition={proposition} showResult isAnswerCorrect={isAnswerCorrect()}>
          {proposition.betDescription}
        </BetDescription>
      )}
      {!isAnswerCorrect() && proposition.state === PropositionState.RESOLVED && (
        <CorrectAnswer>
          {t('propositionCard.correctAnswer')}
          <span>
            {proposition.correctAnswerCode} (x{correctAwnserMultiplier()})
          </span>
        </CorrectAnswer>
      )}
    </StyledPrediction>
  );
};
