import React from 'react';
import { ChatRow } from 'molecules/Chat/ChatRow';
import { RoomChatMessageList } from 'store/roomChat/types';

interface Props {
  messages: RoomChatMessageList[];
}

export const ChatList: React.FC<Props> = ({ messages }) => (
  <>
    {messages.map((message) => (
      <ChatRow
        key={`${message.senderName}-${message.messages[0].text}`}
        avatarUrl={message.avatarUrl}
        messages={message.messages}
        senderName={message.senderName}
        type={message.type}
      />
    ))}
  </>
);
