import styled from 'styled-components';
import { Colors, BorderWidth } from 'style/types';
import { LeagueCard } from 'atoms/Cards/LeagueCard';

export const InviteLeagueCardContainer = styled(LeagueCard)`
  background-color: ${Colors.Black};
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black75};

  grid-template-columns: auto auto 1fr auto;
  grid-template-areas: 'sporticon avatar description members';
`;
