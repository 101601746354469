import React, { PropsWithChildren } from 'react';
import {
  Path,
  PathValue,
  UnpackNestedValue,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { ErrorMessage } from 'atoms/Form';
import { useAppSelector } from 'store/hooks';
import { getLeagueAvatars } from 'store/leagueAvatars/selectors';
import { AvatarSelectModal } from './AvatarSelectModal';
import { AvatarFieldGroup } from './LeagueAvatarSelect.styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LeagueAvatarSelect = <T extends Record<string, any>>({
  name,
  control,
  rules,
  defaultValue,
}: PropsWithChildren<UseControllerProps<T>>): JSX.Element => {
  const avatars = useAppSelector(getLeagueAvatars);
  const { t } = useTranslation('LeagueForm');

  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const { setValue, clearErrors } = useFormContext<T>();

  const handleAvatarSelect = (avatarId: string) => {
    clearErrors(name);
    setValue(name, avatarId as UnpackNestedValue<PathValue<T, Path<T>>>);
  };

  return (
    <AvatarFieldGroup hasError={!!error}>
      <Avatar size={AvatarSize.XXXXL} src={avatars[value as string]?.url} />
      <AvatarSelectModal
        activeAvatarId={avatars[value as string]?.avatarId}
        title={t('selectAvatarModal.title')}
        onSelect={handleAvatarSelect}
      />
      <input
        type="hidden"
        onChange={onChange}
        onBlur={onBlur}
        value={value as string}
        name={fieldName}
        ref={ref}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </AvatarFieldGroup>
  );
};
