import React, { useEffect, useState } from 'react';
import {
  RoomCard,
  RoomCardContent,
  RoomCardHeader,
  RoomCardPromoRibbon,
} from 'atoms/Cards/RoomCard';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RoomCardPersonalRank } from 'atoms/Cards/RoomCard/RoomCardPersonalRank';
import { PlayerCount } from 'atoms/PlayerCount';
import { getRoomByContestAndRoomType } from 'store/rooms/selectors';
import { fetchOrUpdateRoomScores } from 'store/rooms/actions';
import { getLeaderboardUserMovement } from 'store/leaderboardMovement/selectors';
import { RoomScoreUpdatedRoomPayload } from 'hocs/withLongPoll/types/room';
import { getRoomTypeById } from 'store/roomTypes/selectors';

interface Props {
  onSelect: (roomId: string) => void;
  roomTypeId: string;
  contestId: string;
}

export const OpenContestRoomCard: React.FC<Props> = ({ roomTypeId, contestId, onSelect }) => {
  const [init, setInit] = useState(true);
  const room = useAppSelector(getRoomByContestAndRoomType(contestId, roomTypeId));
  const roomType = useAppSelector(getRoomTypeById(roomTypeId));
  const movement = useAppSelector(getLeaderboardUserMovement(contestId, roomTypeId));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (init) {
      dispatch(fetchOrUpdateRoomScores({ room: room as RoomScoreUpdatedRoomPayload, contestId }));
      setInit(false);
    }
  }, [room]);

  const handleRoomClick = () => {
    onSelect(room?.roomId as string);
  };

  return (
    <RoomCard onClick={handleRoomClick}>
      <RoomCardHeader action={<PlayerCount count={room?.capacity || 0} />}>
        {room?.name || ''}
      </RoomCardHeader>
      {roomType.isPromoRoom && <RoomCardPromoRibbon>Featured</RoomCardPromoRibbon>}
      <RoomCardContent noRightPadding>
        <RoomCardPersonalRank
          dollarCents={room?.user?.dollarCentsWon || 0}
          tickets={!!room?.user?.ticketWon}
          rank={room?.user?.rank || 0}
          movement={movement}
        />
      </RoomCardContent>
    </RoomCard>
  );
};
