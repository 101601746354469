import styled, { css } from 'styled-components';
import { TableRow, TableCell } from 'atoms/Table';
import { Colors, FontFamily, FontSize } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface RowProps {
  isUser: boolean;
}

export const LeaderboardRow = styled(TableRow)<RowProps>`
  ${({ isUser }) =>
    isUser &&
    css`
      color: ${Colors.TextSub};
    `}
`;

export const LeaderboardCell = styled(TableCell)<RowProps>`
  ${({ isUser }) =>
    isUser &&
    css`
      position: relative;
      overflow: hidden;

      &::before {
        background-color: ${Colors.Primary};
        content: '';
        display: contents;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: ${pxToRem(4)};
      }

      &::after {
        background-color: ${Colors.Primary};
        content: '';
        display: contents;
        height: ${pxToRem(8)};
        left: ${pxToRem(-2)};
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        top: 50%;
        width: ${pxToRem(8)};
      }
    `}
`;

export const PlayerCell = styled.div`
  align-items: center;
  display: flex;
  font-family: ${FontFamily.Regular};
  font-size: ${FontSize.MS};
  justify-content: flex-start;

  > * {
    margin-right: 0.5rem;
  }
`;
