import styled from 'styled-components';
import React from 'react';
import { TicketCardType } from 'molecules/TicketCard/types';
import { Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { ParagraphStyles } from 'atoms/Paragraph';

export const TicketCardTitle = styled.h2`
  grid-area: title;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.Bold};

  svg {
    color: ${Colors.Secondary};
    height: 1.75rem;
    margin-right: ${Gap.S};
  }
`;

export const TicketInfo = styled.section`
  grid-area: info;
`;

export const TicketQuantity = styled.div`
  grid-area: quantity;
  font-size: ${FontSize.S};
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;

  > span {
    font-family: ${FontFamily.Heading};
    margin-left: ${Padding.XS};
    color: ${Colors.Secondary};
    font-size: ${FontSize.ML};
    letter-spacing: 0.8px;
    font-weight: ${FontWeight.Bold};
  }
`;

interface TicketExpireProps {
  type: TicketCardType;
  label: string;
}

export const TicketExpire = styled.div.attrs<TicketExpireProps>(({ children, label }) => ({
  children: (
    <>
      {label}
      <p>{children}</p>
    </>
  ),
}))<TicketExpireProps>`
  grid-area: expire;
  align-self: end;
  color: ${({ type }) => (type === TicketCardType.Active ? Colors.TextSub : Colors.Black40)};
  font-size: ${FontSize.S};
  text-align: right;

  > p {
    margin: 0;
    font-family: ${FontFamily.Heading};
    font-size: ${FontSize.M};
    color: ${Colors.Secondary};
  }
`;

interface TicketDataProps {
  label: string;
}

export const TicketData = styled.section.attrs<TicketDataProps>(({ children, label }) => ({
  children: (
    <>
      {label}
      <p>{children}</p>
    </>
  ),
}))<TicketDataProps>`
  ${ParagraphStyles};
  font-size: ${FontSize.S};

  margin-bottom: ${Gap.Default};
  &:last-of-type {
    margin: 0;
  }

  > p {
    margin: ${Padding.XXS} 0 0;
    font-size: ${FontSize.M};
    color: ${Colors.Text};
  }
`;
