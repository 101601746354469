import styled from 'styled-components';
import { Colors, FontSize, FontWeight, Padding } from 'style/types';

export const GameCardName = styled.h3`
  color: ${Colors.Text};
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  grid-area: name;
  margin: 0;
  padding: ${Padding.XXS} 0 ${Padding.XS} ${Padding.Default};
  text-align: center;
`;
