import React, { useState, useEffect } from 'react';
import { useInterval } from 'hooks/useInterval';

interface Props {
  countTo: number;
  stepSize?: number;
  stepDuration?: number;
  formatter?: (value: number) => string | number;
}

const defaultFallback = (value: number) => value;

export const AnimatedCounter: React.FC<Props> = ({
  countTo,
  stepSize = 1,
  stepDuration = 3,
  formatter = defaultFallback,
}) => {
  const [step, setStep] = useState(stepSize > 600 ? 100 : stepSize);
  const [init, setInit] = useState(true);
  const [points, setPoints] = useState(0);
  const [delay, setDelay] = useState<number | null>(null);

  const increment = () => {
    if (Math.abs(countTo - points) > 600) {
      setStep(100);
    } else {
      setStep(stepSize);
    }
    if (countTo < points) {
      setPoints(Math.max(points - step, countTo));
      return;
    }
    setPoints(Math.min(points + step, countTo));
  };

  useInterval(increment, delay);

  useEffect(() => {
    if (init) {
      if (points === 0) {
        setPoints(countTo);
      }
      setInit(false);
    } else {
      setDelay(stepDuration);
    }
  }, [countTo]);

  useEffect(() => {
    if (points === countTo) {
      setDelay(null);
    }
  }, [points]);

  return <span>{formatter(points)}</span>;
};
