import styled from 'styled-components';
import { FontSize, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const RoomCardBanner = styled.section`
  align-items: center;
  background-color: hsl(190, 64%, 37%);
  display: flex;
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.SemiBold};
  grid-area: banner;
  height: ${pxToRem(32)};
  padding: 0 ${Padding.Default};

  svg {
    margin-right: ${pxToRem(8)};
  }
`;
