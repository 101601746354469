import { createSelector } from 'reselect';
import { CurriedSelector, RootState } from 'store/types';
import { LeaderboardMovement, LeaderboardMovements } from './types';

const getLeaderboardMovements = (state: RootState) => state.leaderboardsMovement;

const getLeaderboardMovement = (
  contestId: string,
  roomTypeId: string,
): CurriedSelector<LeaderboardMovement | null> =>
  createSelector(getLeaderboardMovements, (leaderboardsMovements) =>
    leaderboardsMovements[contestId] ? leaderboardsMovements[contestId][roomTypeId] : null,
  );

export const getLeaderboardUserMovement = (
  contestId: string,
  roomTypeId: string,
): CurriedSelector<LeaderboardMovements> =>
  createSelector(getLeaderboardMovement(contestId, roomTypeId), (movement) => {
    if (!movement) {
      return LeaderboardMovements.Equal;
    }
    if (!movement.user.currentRank || !movement.user.previousRank) {
      return LeaderboardMovements.Equal;
    }
    if (movement.user.currentRank > movement.user.previousRank) {
      return LeaderboardMovements.Descending;
    }
    if (movement.user.currentRank < movement.user.previousRank) {
      return LeaderboardMovements.Rising;
    }
    return LeaderboardMovements.Equal;
  });
