import React from 'react';
import { BadgeWrapper } from '../Badge.styled';
import { BadgeIcon, BadgeNumber } from './IconNumberBadge.styled';

interface Props {
  iconSrc: string;
  number: number | string;
}

export const IconNumberBadge: React.FC<Props> = ({ iconSrc, number }) => (
  <BadgeWrapper>
    <BadgeIcon src={iconSrc} />
    <BadgeNumber>{number}</BadgeNumber>
  </BadgeWrapper>
);
