import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, Padding } from 'style/types';

interface WrapperProps {
  direction?: 'row' | 'column';
}

export const BadgeWrapper = styled.section<WrapperProps>`
  border: ${BorderWidth.Default} solid ${Colors.Black80};
  border-radius: ${BorderRadius.S};
  display: inline-flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  padding: ${Padding.XXS};
`;
