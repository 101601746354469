import React from 'react';
import { useTranslation } from 'react-i18next';

import wrapperLib from 'services/wrapper';
import { LocationError } from 'services/wrapper/types';
import { ButtonBar } from 'atoms/ButtonBar';
import { Button } from 'atoms/Button';
import { useAppSelector } from 'store/hooks';
import { ClientType } from 'services/pttv/api';
import { ContentArea } from 'atoms/ContentArea';
import { NoResultsCard } from 'molecules/NoResultsCard';
import WinviewLogo from 'assets/icons/WinViewLogoSmall.svg';

interface Props {
  error: LocationError;
}

export const LocationErrorMessage: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation('LocationErrorMessage');
  const { os } = useAppSelector((state) => state.wrapper);

  const getMessageForReason = () => {
    switch (error.message) {
      case 'gps_unavailable':
        return t('detail.gps_unavailable');
      case 'gps_timeout':
        return t('detail.gps_timeout');
      case 'permission_denied':
        return t('detail.permission_denied');
      case 'permission_denied_web':
        return t('detail.permission_denied_web');
      default:
        return t('detail.other');
    }
  };

  const openLocationServices = () => {
    switch (error.message) {
      case 'gps_disabled':
        wrapperLib.deviceGeoLocationSettingsOpen();
        break;
      case 'permission_denied':
        wrapperLib.appSettingsOpen();
        break;
      default:
      // Do nothing
    }
  };

  return (
    <NoResultsCard title={t('title')} icon={<WinviewLogo />}>
      <ContentArea>
        <p>{t('message')}</p>
        {getMessageForReason()}
      </ContentArea>
      {/*
          Do not show location settings button when:
          - Web
          - iOS in case the error reason is gps_disabled (it's not possible to programmatically open gps settins on iOS)
        */}
      {os !== ClientType.WEB && (os !== ClientType.IOS || error.message !== 'gps_disabled') && (
        <ButtonBar>
          <Button onClick={openLocationServices}>{t('buttonTitle')}</Button>
        </ButtonBar>
      )}
    </NoResultsCard>
  );
};
