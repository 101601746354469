import styled from 'styled-components';
import { Gap } from 'style/types';
import { LeaderBoardUserContainer, LeaderBoardUserTitle } from '../LeaderboardUser.styled';

export const LeagueLeaderBoardUserContainer = styled(LeaderBoardUserContainer)`
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'header header header'
    'rank image score';
`;

export const LeagueLeaderBoardUserTitle = styled(LeaderBoardUserTitle)`
  margin-top: ${Gap.ML};
`;
