import styled, { css } from 'styled-components';
import React from 'react';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';
import ChatIcon from 'assets/icons/ChatIcon.svg';
import { pxToRem } from 'utils/pxToRem';
import { Pill } from 'atoms/Pill';

interface Props {
  messageType: ChatBubbleTypes;
}

export const LastMessage = styled.section<Props>`
  align-items: center;
  background-color: ${Colors.Black90};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.Black20};
  display: flex;
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.SemiBold};
  grid-area: message;
  height: 100%;
  padding-left: ${Padding.Default};

  > ${StyledAvatar} {
    margin-right: 1rem;
  }

  ${({ messageType }) =>
    messageType === ChatBubbleTypes.Winview &&
    css`
      ${StyledAvatar} {
        border: ${BorderWidth.Large} solid ${Colors.Primary};
      }
    `}
`;

export const LastMessageIcon = styled.div.attrs(({ children }) => ({
  children: (
    <>
      <ChatIcon />
      {children}
    </>
  ),
}))`
  align-items: center;
  background-color: ${Colors.Black90};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.Primary};
  display: flex;
  grid-area: button;
  height: 100%;
  justify-content: center;
  position: relative;

  > svg {
    width: ${pxToRem(30)};
  }

  > ${Pill} {
    position: absolute;
    transform: translate(30%, -30%);
    top: 0;
    right: 0;
  }
`;

export const StyledLastMessageButton = styled.section.attrs<Props>({
  role: 'button',
})<Props>`
  background: transparent;
  border: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr ${pxToRem(60)};
  grid-template-areas: 'message button';
  gap: ${Gap.XS};
  height: ${pxToRem(60)};
  width: 100%;
`;
