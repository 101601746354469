import { createSelector } from 'reselect';
import type { CurriedSelector, RootState } from 'store/types';
import { PropositionsMetaState } from './types';

export const getPropositionsMeta = (state: RootState): PropositionsMetaState =>
  state.propositionsMeta;

export const isPropositionViewed = (propositionId: string): CurriedSelector<boolean> =>
  createSelector(
    getPropositionsMeta,
    (propositionsMeta) => !!propositionsMeta[propositionId]?.viewed,
  );
