import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { ContestHistoryRoomLeaderboardUser } from 'molecules/LeaderboardUser/RoomLeaderboardUser/ContestHistoryRoomLeaderboardUser';
import { OverlayHeader } from 'molecules/OverlayHeader';
import { RoomLeaderboardHeader } from 'molecules/RoomLeaderboard';
import { RoomLeaderboardList } from 'organisms/RoomLeaderboardList';
import { RoomLeaderboardUser } from 'molecules/LeaderboardUser';
import { ContentArea } from 'atoms/ContentArea';
import { RoomChatModal } from 'organisms/ChatModal';
import { RoomLeaderboardContest, RoomLeaderboardWrapper } from './RoomLeaderboard.styled';

interface Props {
  contestId: string;
  roomId: string;
  isContestHistory?: boolean;
  onClose?: () => void;
}

export const RoomLeaderboard: React.FC<Props> = ({
  roomId,
  contestId,
  isContestHistory,
  onClose,
}) => {
  const { goBack } = useHistory();
  const { t } = useTranslation('Leaderboard');

  return (
    <RoomLeaderboardWrapper>
      <OverlayHeader type={OverlayHeaderActions.Chevron} onClose={onClose || goBack}>
        {t('roomLeaderBoard.title')}
      </OverlayHeader>

      <RoomLeaderboardContest>
        <RoomLeaderboardHeader contestId={contestId} />

        {isContestHistory ? (
          <ContestHistoryRoomLeaderboardUser contestId={contestId} roomId={roomId} />
        ) : (
          <RoomLeaderboardUser roomId={roomId} />
        )}
        <RoomLeaderboardList
          roomId={roomId}
          contestId={contestId}
          isContestHistory={isContestHistory}
        />
      </RoomLeaderboardContest>
      {!isContestHistory && (
        <ContentArea>
          <RoomChatModal roomId={roomId} />
        </ContentArea>
      )}
    </RoomLeaderboardWrapper>
  );
};
