import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { DisplayName, StyledPlayerResult, Title } from './SearchResultPlayer.styled';

interface Props {
  avatarUrl?: string;
  displayName: string;
  callback: () => void;
  showResult?: boolean;
}

export const SearchResultPlayer: React.FC<Props> = ({
  avatarUrl,
  displayName,
  callback,
  showResult = false,
}) => {
  const { t } = useTranslation('InviteFriend');

  return (
    <AnimatePresence exitBeforeEnter={false}>
      {showResult && (
        <StyledPlayerResult>
          <Title>
            {displayName ? t('searchPlayer.result.title') : t('searchPlayer.noResult.title')}
          </Title>
          <Avatar src={avatarUrl} size={AvatarSize.XXL} />
          {displayName && (
            <>
              <DisplayName>{displayName}</DisplayName>
              <Button scheme={ButtonSchemes.Secondary} onClick={callback} size={ButtonSize.Small}>
                {t('searchPlayer.result.addButton')}
              </Button>
            </>
          )}
        </StyledPlayerResult>
      )}
    </AnimatePresence>
  );
};
