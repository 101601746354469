import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalPage } from 'atoms/ModalPage';
import { ChatMessageForm } from 'molecules/Chat/MessageForm';
import { ChatList } from 'organisms/ChatList';
import { LastMessageButton } from 'molecules/Chat/LastMessageButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getChatMessageListByRoomId,
  getLastChatMessage,
  getUnseenMessagesCount,
} from 'store/roomChat/selectors';
import { sendRoomChatMessage, setSeenRoomChatMessagesByRoom } from 'store/roomChat/actions';
import { ChatModalContent, ChatModalContentArea, ChatModalFooter } from '../ChatModal.styled';

interface Props {
  roomId: string;
}

export const RoomChatModal: React.FC<Props> = ({ roomId }) => {
  const chatListRef = useRef(null);
  const messages = useAppSelector(getChatMessageListByRoomId(roomId));
  const lastMessage = useAppSelector(getLastChatMessage(roomId));
  const unseenCount = useAppSelector(getUnseenMessagesCount(roomId));
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('Leaderboard');

  const handleChatButtonClick = () => {
    setIsOpen(true);
    dispatch(setSeenRoomChatMessagesByRoom(roomId));
  };

  const handleMessageSubmit = (text: string) => {
    dispatch(sendRoomChatMessage({ roomId, text }));
  };

  const scrollToBottom = () => {
    if (chatListRef.current) {
      (chatListRef.current as HTMLDivElement).scrollTop = 999999;
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages, chatListRef]);

  // Needed for initial scroll to bottom
  setTimeout(scrollToBottom, 250);

  return (
    <>
      <LastMessageButton
        lastMessage={lastMessage}
        unseenCount={unseenCount}
        onClick={handleChatButtonClick}
      />
      <ModalPage
        title={t('roomLeaderBoard.title')}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ChatModalContentArea>
          <ChatModalContent ref={chatListRef}>
            <ChatList messages={messages} />
          </ChatModalContent>
          <ChatModalFooter>
            <ChatMessageForm onMessageSubmit={handleMessageSubmit} />
          </ChatModalFooter>
        </ChatModalContentArea>
      </ModalPage>
    </>
  );
};
