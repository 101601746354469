import React from 'react';
import { Table, TableRow, TableHeadCell } from 'atoms/Table';
import { useAppSelector } from 'store/hooks';
import {
  getUserByContestIdAndRoomTypeId,
  getPlayersByContestIdAndRoomTypeId,
} from 'store/contestInfo/selectors';
import { RoomLeaderboardListItem } from 'molecules/LeaderboardListItem';

interface Props {
  roomTypeId: string;
  contestId: string;
}

export const RoomTypeLeaderboardList: React.FC<Props> = ({ roomTypeId, contestId }) => {
  const players = useAppSelector(getPlayersByContestIdAndRoomTypeId(contestId, roomTypeId));
  const user = useAppSelector(getUserByContestIdAndRoomTypeId(contestId, roomTypeId));

  if (!players) {
    return null;
  }

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeadCell width="12%" align="center">
            Rank
          </TableHeadCell>
          <TableHeadCell>Player</TableHeadCell>
          <TableHeadCell width="15%">Points</TableHeadCell>
          <TableHeadCell width="18%">Payout</TableHeadCell>
        </TableRow>
      </thead>
      <tbody>
        {players.map((player) => (
          <RoomLeaderboardListItem
            key={player.userId}
            player={player}
            isUser={player.userId === (user?.userId || '')}
            contestId={contestId}
          />
        ))}
      </tbody>
    </Table>
  );
};
