import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TicketsIcon from 'assets/icons/TicketsIcon.svg';
import { useAppSelector } from 'store/hooks';
import { getDetailedSelectionInvestment } from 'store/roomSelection/selectors';
import { formatCurrency } from 'utils/formatters';
import { JoinRoomConfirmContentContainer, JoinRoomFees } from './JoinRoom.styled';

export const JoinRoomConfirmContent: React.FC = () => {
  const investment = useAppSelector(getDetailedSelectionInvestment);
  const [isForFree, setIsForFree] = useState(true);
  const [showFees, setShowFees] = useState(true);
  const { t } = useTranslation('RoomSelection');

  useEffect(() => {
    setIsForFree(!investment.roomTypeTotalDollarCents && !investment.roomTypeTotalTickets.length);
  }, [investment]);

  useEffect(() => {
    setShowFees(
      !!investment.roomTypeTotalDollarCents || investment.roomTypeTotalTickets.length > 0,
    );
  }, [investment]);

  return (
    <JoinRoomConfirmContentContainer>
      {isForFree
        ? t('confirmDialog.hasEnoughDollars.freeRoom')
        : t('confirmDialog.hasEnoughDollars.description')}
      {!isForFree && (
        <>
          <h2>{t('confirmDialog.hasEnoughDollars.feesTitle')}</h2>
          {showFees && (
            <JoinRoomFees>
              {!!investment.roomTypeTotalDollarCents &&
                formatCurrency(investment.roomTypeTotalDollarCents / 100)}

              {investment.roomTypeTotalTickets.length > 0 && (
                <>
                  <TicketsIcon />
                  {investment.roomTypeTotalTickets.length}
                </>
              )}
            </JoinRoomFees>
          )}
        </>
      )}
    </JoinRoomConfirmContentContainer>
  );
};
