import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { InfoButton } from 'atoms/InfoButton';
import { ModalPage } from 'atoms/ModalPage';
import { RoomInfo } from 'organisms/RoomInfo';
import { RoomInfoModalContentArea, RoomInfoModalFooter } from './RoomInfoModal.styled';

interface Props {
  roomTypeId: string;
  title: string;
  onSelect?: () => void;
  isSelected: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const RoomInfoModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  roomTypeId,
  title,
  onSelect,
  isSelected,
}) => {
  const { t } = useTranslation('RoomInformation');
  const handleSelectButtonClick = () => {
    if (!onSelect) {
      return;
    }
    onSelect();
    setIsOpen(false);
  };

  const handleInfoButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <InfoButton onClick={handleInfoButtonClick} />

      <ModalPage title={title} isOpen={isOpen} onClose={handleCloseButtonClick}>
        <RoomInfoModalContentArea
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <RoomInfo roomTypeId={roomTypeId} />

          {onSelect && (
            <RoomInfoModalFooter>
              <Button scheme={ButtonSchemes.Secondary} onClick={handleSelectButtonClick}>
                {isSelected ? t('modal.buttonDeselect') : t('modal.buttonSelect')}
              </Button>
            </RoomInfoModalFooter>
          )}
        </RoomInfoModalContentArea>
      </ModalPage>
    </>
  );
};
