import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, Gap, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { Pill } from 'atoms/Pill';
import { AnswerCode, PropositionState } from 'services/pttv/api/constants';
import { PropositionListItem } from 'store/propositions/types';

const getBackgroundColor = ({ state, hasBet }: PropositionListItem): Colors => {
  if (state === PropositionState.OPEN && !hasBet) {
    return Colors.ActiveBackground;
  }
  if (state === PropositionState.OPEN && hasBet) {
    return Colors.InactiveBackground;
  }
  return Colors.Black80;
};

const getBorderColor = (
  { state, hasBet, correctAnswerCode }: PropositionListItem,
  isAnswerCorrect: boolean,
): Colors | string => {
  if (state === PropositionState.OPEN && !hasBet) {
    return 'HSL(228, 14%, 41%)';
  }
  if (state === PropositionState.OPEN && hasBet) {
    return Colors.InactiveBackground;
  }
  if (state === PropositionState.RESOLVED && correctAnswerCode === AnswerCode.UNDECIDED && hasBet) {
    return Colors.Black50;
  }
  if (state === PropositionState.RESOLVED && isAnswerCorrect && hasBet) {
    return Colors.Success;
  }
  if (state === PropositionState.RESOLVED && !isAnswerCorrect && hasBet) {
    return Colors.Error;
  }
  return Colors.Black80;
};

interface InputProps {
  proposition: PropositionListItem;
  isAnswerCorrect: boolean;
}

interface AttrsProps {
  role: string;
  backgroundColor: Colors | string;
  borderColor: Colors | string;
  borderWidth: BorderWidth;
}

export const PropositionCardStyled = styled.article.attrs<InputProps, AttrsProps>(
  ({ proposition, isAnswerCorrect }) => ({
    role: 'button',
    backgroundColor: getBackgroundColor(proposition),
    borderColor: getBorderColor(proposition, isAnswerCorrect),
    borderWidth:
      proposition.state === PropositionState.RESOLVED ? BorderWidth.Large : BorderWidth.Default,
  }),
)<InputProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderWidth }) => borderWidth} solid ${({ borderColor }) => borderColor};
  border-radius: ${BorderRadius.Default};
  box-sizing: border-box;
  color: ${Colors.Text};
  display: grid;
  column-gap: ${Gap.Default};
  grid-template-columns: ${pxToRem(36)} 1fr auto auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'index content pill action'
    'index prediction prediction action';
  min-height: ${pxToRem(80)};
  padding: ${Padding.Default};
  position: relative;

  + article {
    margin-top: ${Gap.Default};
  }

  > ${Pill} {
    align-self: center;
    grid-area: 1/3/3/4;
    margin-left: ${Gap.Default};
  }
`;
