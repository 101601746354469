import type { RootState } from 'store/types';
import { CashBetPageType } from './types';

export const getCashBetUrlForType = (state: RootState, type: CashBetPageType): string | null => {
  const {
    cashBet: { autoLoginUrl, depositUrl, kycUrl, loginUrl, transactionHistoryUrl, withdrawalUrl },
  } = state;

  switch (type) {
    case CashBetPageType.AutoLogin:
      return autoLoginUrl;
    case CashBetPageType.Deposit:
      return depositUrl;
    case CashBetPageType.KYC:
      return kycUrl;
    case CashBetPageType.Login:
      return loginUrl;
    case CashBetPageType.TransactionHistory:
      return transactionHistoryUrl;
    case CashBetPageType.Withdrawal:
      return withdrawalUrl;
    default:
      return null;
  }
};
