import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontSize, Gap } from 'style/types';
import { formatOrdinals } from 'utils/formatters';
import { DollarCentsAndTickets } from 'atoms/DollarCentsAndTickets';
import { DollarCentsAndTicketsWrapper } from 'atoms/DollarCentsAndTickets/DollarCentsAndTickets.styled';
import { Movement } from 'atoms/Movement';
import { LeaderboardMovements } from 'store/leaderboardMovement/types';
import { RoomTypeField } from './RoomTypeField';
import { RoomCardPersonalRankType } from './types';
import { RoomCardAction } from './RoomCardAction';

export const PersonalRankContainer = styled.section`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'rank pay action';

  span.pay-dollarCents + div {
    margin-left: ${Gap.S};
  }

  ${DollarCentsAndTicketsWrapper} {
    line-height: initial;
  }
`;

interface Props {
  dollarCents: number;
  tickets: boolean;
  rank: number;
  movement?: LeaderboardMovements;
  type?: RoomCardPersonalRankType;
}

export const RoomCardPersonalRank: React.FC<Props> = ({
  dollarCents,
  tickets,
  rank,
  movement,
  type = RoomCardPersonalRankType.Regular,
}) => {
  const { t } = useTranslation('Cards');

  return (
    <PersonalRankContainer>
      <RoomTypeField
        label={
          type === RoomCardPersonalRankType.Regular
            ? t('roomCard.personalRank.regular.rank')
            : t('roomCard.personalRank.history.rank')
        }
        orientation="column"
        valueSize={FontSize.XXXL}
      >
        {!!rank && formatOrdinals(rank)}
        {movement && <Movement direction={movement} />}
      </RoomTypeField>
      <RoomTypeField
        label={
          type === RoomCardPersonalRankType.Regular
            ? t('roomCard.personalRank.regular.pays')
            : t('roomCard.personalRank.history.pays')
        }
        orientation="column"
        valueSize={FontSize.XXXL}
      >
        <DollarCentsAndTickets dollarCents={dollarCents} tickets={tickets} />
      </RoomTypeField>
      <RoomCardAction showAction />
    </PersonalRankContainer>
  );
};
