import styled from 'styled-components';
import { TicketsWrapper } from 'atoms/Tickets/Tickets.styled';
import { Gap } from 'style/types';

export const Winnings = styled.div`
  display: flex;

  svg {
    height: 1em;
  }

  ${TicketsWrapper} {
    margin: 0 ${Gap.Default} 0 0;
  }
`;
