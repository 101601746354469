import styled, { css, keyframes } from 'styled-components';
import { BorderRadius, Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { ChatBubbleTypes } from './Bubble.theme';

interface Props {
  type: ChatBubbleTypes;
  isNew: boolean;
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ChatBubble = styled.section<Props>`
  background-color: ${({ theme, type }) => theme.chat.bubble[type].backgroundColor};
  border-radius: ${BorderRadius.Default};
  color: ${({ theme, type }) => theme.chat.bubble[type].color};
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.SemiBold};
  letter-spacing: 0.3px;
  padding: ${Padding.S};
  position: relative;
  z-index: 1;

  &:last-of-type::after {
    border-color: ${({ theme, type }) => theme.chat.bubble[type].backgroundColor};
    border-style: solid;
    bottom: 0.5rem;
    content: '';
    height: 1.25rem;
    position: absolute;
    width: 2.25rem;
    z-index: 0;
    ${({ type }) =>
      type === ChatBubbleTypes.Right
        ? css`
            border-width: 0 0 0 1.5rem;
            border-radius: 0 0 0 100%;
            clip: rect(0, 2.25rem, 3rem, 1rem);
            right: -0.75rem;
          `
        : css`
            border-width: 0 1.5rem 0 0;
            border-radius: 0 0 100% 0;
            clip: rect(0, 1.25rem, 3rem, 0);
            left: -0.75rem;
          `}
  }

  + section {
    margin-top: ${Gap.Default};
  }

  ${({ isNew }) =>
    isNew &&
    css`
      &::before {
        animation: ${rotate} 0.5s linear infinite;
        border-width: 0.5rem;
        border-style: solid;
        border-color: transparent ${Colors.White};
        border-radius: 50%;
        bottom: 0.5rem;
        content: '';
        height: 0;
        opacity: 0.5;
        position: absolute;
        right: 0.5rem;
        width: 0;
      }
    `}
`;
