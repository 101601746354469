import { createSelector } from 'reselect';
import { GetLeagueHistoryResponse, LeagueHistory } from 'services/pttv/api/leagues/types';
import type { CurriedSelector, RootState } from 'store/types';
import { LeagueHistoryState } from './types';

export const getLeagueHistory = (state: RootState): LeagueHistoryState => state.leagueHistory;

export const getLeagueHistoryByName = (
  leagueName: string,
): CurriedSelector<GetLeagueHistoryResponse> =>
  createSelector(getLeagueHistory, (leagueHistories) =>
    [...(leagueHistories[leagueName] || [])].sort(
      (a, b) => b.game.broadcastDate - a.game.broadcastDate,
    ),
  );

export const getLeagueHistoryByNameAndContestId = (
  leagueName: string,
  contestId: string,
): CurriedSelector<LeagueHistory | undefined> =>
  createSelector(getLeagueHistoryByName(leagueName), (leagueHistory) =>
    leagueHistory.find(({ contests }) =>
      contests.some((contest) => contest.contestId === contestId),
    ),
  );
