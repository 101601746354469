import styled from 'styled-components';
import { Colors, FontFamily, FontSize, Padding, Gap, FontWeight } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { CheckMarkWrapper } from 'atoms/CheckMark';

interface WrapperProps {
  isLocked: boolean;
}

export const HeaderWrapper = styled.header<WrapperProps>`
  align-items: center;
  background-color: hsl(190, 21%, 33%);
  color: ${Colors.White};
  display: flex;
  grid-area: header;
  justify-content: space-between;
  min-height: ${pxToRem(48)};

  opacity: ${({ isLocked }) => (isLocked ? 0.35 : 1)};
  padding: 0 ${Padding.Default} 0;
`;

export const HeaderTitle = styled.h2`
  align-items: center;
  display: flex;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.L};
  font-weight: ${FontWeight.Regular};
  letter-spacing: 0.91px;
  margin: ${Gap.S} ${Gap.S} ${Gap.S} 0;

  line-height: 1.2;
  text-transform: uppercase;
  text-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.29);

  > svg {
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
    min-height: ${pxToRem(16)};
    min-width: ${pxToRem(16)};
    margin-right: ${Gap.Default};
  }
`;

export const HeaderActions = styled.div`
  align-items: center;
  display: flex;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.L};
  font-weight: ${FontWeight.Regular};
  justify-content: center;

  ${CheckMarkWrapper} {
    margin-left: ${Gap.Default};
  }

  > svg {
    margin-left: ${Gap.Default};
  }

  svg {
    height: ${pxToRem(20)};
  }

  > svg {
    margin-left: ${Gap.S};
  }
`;
