import styled from 'styled-components';
import { Colors, FontFamily, FontWeight } from 'style/types';
import ribbonStar from 'assets/images/ribbonStar.svg';

export const RoomCardPromoRibbon = styled.section`
  background-color: ${Colors.Tertriary};
  background-image: url('${ribbonStar}');
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  clip-path: polygon(0 0, 100% 0, calc(100% - 0.5rem) 50%, 100% 100%, 0 100%);
  display: inline-block;
  font-family: ${FontFamily.Heading};
  font-weight: ${FontWeight.Medium};
  grid-area: ribbon;
  justify-self: start;
  margin: 0.5rem 0 0;
  padding: 0.25rem 1.5rem 0.25rem 2rem;
  text-transform: uppercase;
`;
