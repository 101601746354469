import styled from 'styled-components';
import React from 'react';
import { Colors } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import ArrowIcon from 'assets/icons/ArrowIcon.svg';

export const ChatMessageSubmit = styled.button.attrs({
  type: 'submit',
  children: <ArrowIcon />,
})`
  align-items: center;
  background-color: ${Colors.Primary};
  border-radius: 50%;
  border: none;
  color: ${Colors.Text};
  display: flex;
  height: ${pxToRem(30)};
  justify-content: center;
  padding: 0;
  width: ${pxToRem(30)};

  > svg {
    width: ${pxToRem(14)};
  }

  &:hover {
    background-color: ${Colors.PrimaryDark};
  }

  &:active {
    background-color: ${Colors.PrimaryLight};
  }
`;
