import React, { MouseEvent, useState } from 'react';
import { ButtonSize, ButtonSchemes } from 'atoms/Button/button.theme';
import { Button } from 'atoms/Button';
import {
  RoomCard,
  RoomCardContent,
  RoomCardAside,
  RoomCardHeader,
  RoomCardPlayerCount,
  RoomCardPromoRibbon,
} from 'atoms/Cards/RoomCard';
import { useAppSelector } from 'store/hooks';
import { RoomInfoModal } from 'organisms/RoomInfoModal';

interface Props {
  roomTypeId: string;
  onLeaveButtonClick: (roomTypeId: string) => void;
}

export const JoinedRoomCard: React.FC<Props> = ({ roomTypeId, onLeaveButtonClick }) => {
  const roomType = useAppSelector((state) => state.roomTypes[roomTypeId]);
  const [isRoomInfoOpen, setIsRoomInfoOpen] = useState(false);

  const handleLeaveButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onLeaveButtonClick(roomTypeId);
  };

  return (
    <RoomCard>
      <RoomCardHeader
        isGlobal={roomType.leaderboardEnabled}
        isLocked={false}
        action={
          <Button
            size={ButtonSize.Tiny}
            scheme={ButtonSchemes.Grey}
            onClick={handleLeaveButtonClick}
          >
            Leave
          </Button>
        }
      >
        {roomType.name}
      </RoomCardHeader>
      {roomType.isPromoRoom && <RoomCardPromoRibbon>Featured</RoomCardPromoRibbon>}
      <RoomCardContent isLocked={false}>{roomType.shortDescription}</RoomCardContent>
      <RoomCardAside isLocked={false}>
        <RoomInfoModal
          roomTypeId={roomType.roomTypeId}
          title={roomType.name}
          isSelected
          isOpen={isRoomInfoOpen}
          setIsOpen={setIsRoomInfoOpen}
        />
        <RoomCardPlayerCount label="Max" count={roomType.capacity} />
      </RoomCardAside>
    </RoomCard>
  );
};
