import React from 'react';
import { formatOrdinals, formatNumber } from 'utils/formatters';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { LeagueLeaderBoardUser } from 'store/leagueLeaderboard/types';
import { FieldWrapper, FieldLabel, FieldValue, ImageWrapper } from '../LeaderboardUser.styled';
import {
  LeagueLeaderBoardUserContainer,
  LeagueLeaderBoardUserTitle,
} from './LeaderboardUser.styled';

interface Props {
  user: LeagueLeaderBoardUser;
  title: string;
}

export const LeagueLeaderboardUser: React.FC<Props> = ({ user, title }) => (
  <LeagueLeaderBoardUserContainer>
    <LeagueLeaderBoardUserTitle>{title}</LeagueLeaderBoardUserTitle>
    <FieldWrapper>
      <FieldLabel>Rank</FieldLabel>
      <FieldValue>{formatOrdinals(user.rank)}</FieldValue>
    </FieldWrapper>
    <ImageWrapper>
      <Avatar size={AvatarSize.XXXL} src={user.avatarUrl} />
    </ImageWrapper>
    <FieldWrapper>
      <FieldLabel>Score</FieldLabel>
      <FieldValue>{formatNumber(user.points)}</FieldValue>
    </FieldWrapper>
  </LeagueLeaderBoardUserContainer>
);
