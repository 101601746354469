import styled from 'styled-components';
import { Colors, FontFamily, FontSize } from 'style/types';
import { formatNumber } from 'utils/formatters';

const getResultColor = (success: boolean): Colors => (success ? Colors.Success : Colors.Error);

interface InputProps {
  result: number;
  success: boolean;
}

interface AttrsProps {
  color: Colors;
}

export const PropositionCardResult = styled.section.attrs<InputProps, AttrsProps>(
  ({ result, success }) => ({
    children: formatNumber(result, 0, true),
    color: getResultColor(success),
  }),
)<InputProps>`
  align-self: center;
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XL};
  grid-area: action;
`;
