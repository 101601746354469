import React from 'react';
import GlobeIcon from 'assets/icons/GlobeIcon.svg';
import { HeaderActions, HeaderTitle, HeaderWrapper } from './RoomCardHeader.styled';

interface Props {
  isLocked?: boolean;
  isGlobal?: boolean;
  action: JSX.Element;
  avatar?: JSX.Element | null;
}

export const RoomCardHeader: React.FC<Props> = ({
  children,
  action,
  avatar,
  isLocked = false,
  isGlobal = false,
}) => (
  <HeaderWrapper isLocked={isLocked}>
    <HeaderTitle>
      {!!isGlobal && <GlobeIcon />}
      {!!avatar && avatar}
      {children}
    </HeaderTitle>
    <HeaderActions>{action}</HeaderActions>
  </HeaderWrapper>
);
