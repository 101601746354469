import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  PropositionCardStyled,
  PropositionCardIndex,
  PropositionCardContent,
  PropositionCardPrediction,
  PropositionCardAction,
  PropositionCardResult,
  PropositionCardResultIcon,
} from 'atoms/Cards/PropositionCard';
import { PropositionListItem } from 'store/propositions/types';
import { Pill } from 'atoms/Pill';
import { Colors } from 'style/types';
import { AnswerCode, PropositionState } from 'services/pttv/api/constants';
import { getBetByPropositionId } from 'store/bets/selectors';
import { useAppSelector } from 'store/hooks';
import { getPropositionResult } from 'store/propositions/selectors';
import { isPropositionViewed } from 'store/propositionsMeta/selectors';

interface Props {
  proposition: PropositionListItem;
}

export const PropositionCard: React.FC<Props> = ({ proposition }) => {
  const bet = useAppSelector(getBetByPropositionId(proposition.propositionId));
  const result = useAppSelector(getPropositionResult(proposition.propositionId));
  const isViewed = useAppSelector(isPropositionViewed(proposition.propositionId));
  const { push } = useHistory();

  const isAnswerCorrect = () => proposition.correctAnswerCode === bet?.answerCode;
  const showResult = () => proposition.state === PropositionState.RESOLVED && proposition.hasBet;

  const location = {
    pathname: `/games/${proposition.gameId}/contests/${proposition.contestId}/proposition`,
    state: { propositionId: proposition.propositionId },
  };

  const handlePropositionCardClick = () => {
    if (proposition.state === PropositionState.OPEN) {
      push(location);
    }
  };

  return (
    <PropositionCardStyled
      proposition={proposition}
      isAnswerCorrect={isAnswerCorrect()}
      onClick={handlePropositionCardClick}
    >
      <PropositionCardIndex proposition={proposition} isAnswerCorrect={isAnswerCorrect()}>
        {proposition.openNumber}
      </PropositionCardIndex>
      <PropositionCardContent proposition={proposition}>
        {proposition.description}
      </PropositionCardContent>
      {!isViewed && proposition.state === PropositionState.OPEN && !proposition.hasBet && (
        <Pill backgroundColor={Colors.Tertriary}>New</Pill>
      )}
      <PropositionCardPrediction proposition={proposition} bet={bet} />

      {showResult() ? (
        <>
          {isAnswerCorrect() && (
            <PropositionCardResultIcon correctAnswer={proposition.correctAnswerCode} success />
          )}
          {proposition.correctAnswerCode !== AnswerCode.UNDECIDED && (
            <PropositionCardResult result={result} success={isAnswerCorrect()} />
          )}
        </>
      ) : (
        <PropositionCardAction to={location} proposition={proposition} />
      )}
    </PropositionCardStyled>
  );
};
