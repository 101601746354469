import React from 'react';
import { useAppSelector } from 'store/hooks';
import { getRoomUserByRoomId, getRoomById } from 'store/rooms/selectors';
import { getLeaderboardUserMovement } from 'store/leaderboardMovement/selectors';
import { LeaderboardUser } from './LeaderboardUser';

interface Props {
  roomId: string;
}

export const RoomLeaderboardUser: React.FC<Props> = ({ roomId }) => {
  const room = useAppSelector(getRoomById(roomId));
  const user = useAppSelector(getRoomUserByRoomId(roomId));
  const movement = useAppSelector(getLeaderboardUserMovement(room.contestId, room.roomTypeId));

  if (!room) {
    return null;
  }

  return <LeaderboardUser user={user} movement={movement} title={room.name} />;
};
