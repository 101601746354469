import styled from 'styled-components';
import { Colors } from 'style/types';

export const DefaultPageWrapper = styled.section`
  background-color: ${Colors.Black};
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.section`
  overflow: scroll;
`;
