import styled from 'styled-components';
import { BorderRadius, Colors, Padding } from 'style/types';
import { GameCardType } from 'molecules/GameCard/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  type: GameCardType;
  isLocked?: boolean;
}

const getCardColor = (type: GameCardType, isLocked: boolean): Colors => {
  if (isLocked) {
    return Colors.Black90;
  }

  switch (type) {
    case GameCardType.Open:
    case GameCardType.Joined:
      return Colors.ActiveBackground;

    case GameCardType.Pending:
      return Colors.Black90;

    case GameCardType.Upcoming:
      return Colors.Black90;

    default:
      return Colors.Black90;
  }
};

export const GameCard = styled.article.attrs({
  role: 'button',
})<Props>`
  background-color: ${({ type, isLocked }) => getCardColor(type, !!isLocked)};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.White};
  display: grid;
  grid-template-columns: minmax(${pxToRem(92)}, auto) 1fr auto;
  grid-template-rows: 1fr repeat(2, auto);
  grid-template-areas:
    'time game action'
    'time title action'
    'time name action'
    'description description description';
  padding: ${Padding.XS} ${Padding.Default} ${Padding.XS} 0;
`;
