import { createSelector } from 'reselect';
import i18n from 'i18next';
import { CurriedSelector, RootState } from 'store/types';
import { getRoomById } from 'store/rooms/selectors';
import { getContestByRoomId } from 'store/contests/selectors';
import { ContestState } from 'services/pttv/api/constants';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { RoomChatMessage, RoomChatMessageList, LastRoomChatMessage, RoomChatState } from './types';
import { getChatAvatarUrl, getChatMessageType, getChatSenderName, isMuted } from './utils';

const getUserMeta = (state: RootState) => state.userMeta;

export const getMessages = (state: RootState): RoomChatState => state.roomChat || {};

export const getMessagesByRoomId = (roomId: string): CurriedSelector<RoomChatMessage[]> =>
  createSelector(getRoomById(roomId), getMessages, getUserMeta, (room, messages, userMeta) =>
    (messages[roomId] || []).filter(
      (message) =>
        room.contestId === message.contestId ||
        (message.roomId === roomId && !isMuted(message, userMeta)),
    ),
  );

export const getChatMessageListByRoomId = (
  roomId: string,
): CurriedSelector<RoomChatMessageList[]> =>
  createSelector(
    getRoomById(roomId),
    getContestByRoomId(roomId),
    getMessagesByRoomId(roomId),
    (room, contest, messages) => {
      if (!room || !contest || contest.state !== ContestState.OPEN) {
        return [];
      }

      return messages.reduce<RoomChatMessageList[]>((acc, message) => {
        if (acc[acc.length - 1]?.senderId === message.senderId) {
          acc[acc.length - 1].messages.push(message);
          return acc;
        }
        const player = room.players.find((sender) => sender.userId === message.senderId);
        const messageType = getChatMessageType(room, message);
        acc.push({
          senderId: message.senderId,
          senderName: getChatSenderName(player?.displayName, messageType),
          avatarUrl: getChatAvatarUrl(player?.avatarUrl, messageType),
          messages: [message],
          type: messageType,
        });
        return acc;
      }, []);
    },
  );

export const getLastChatMessage = (roomId: string): CurriedSelector<LastRoomChatMessage | null> =>
  createSelector(
    getRoomById(roomId),
    getContestByRoomId(roomId),
    getMessagesByRoomId(roomId),
    (room, contest, messages) => {
      const message = messages.pop();
      if (!room || !contest || contest.state !== ContestState.OPEN) {
        return null;
      }
      if (!message) {
        return {
          senderId: '',
          senderName: getChatSenderName(undefined, ChatBubbleTypes.Winview),
          avatarUrl: getChatAvatarUrl(undefined, ChatBubbleTypes.Winview),
          message: i18n.t('Leaderboard:roomLeaderBoard.defaultChatMessage'),
          type: ChatBubbleTypes.Winview,
        };
      }

      const player = room.players.find((sender) => sender.userId === message.senderId);
      const messageType = getChatMessageType(room, message);

      return {
        senderId: message.senderId,
        senderName: getChatSenderName(player?.displayName, messageType),
        avatarUrl: getChatAvatarUrl(player?.avatarUrl, messageType),
        message: message.text,
        type: messageType,
      };
    },
  );

export const getUnseenMessagesCount = (roomId: string): CurriedSelector<number> =>
  createSelector(
    getRoomById(roomId),
    getContestByRoomId(roomId),
    getMessagesByRoomId(roomId),
    (room, contest, messages) => {
      if (!room || !contest || contest.state !== ContestState.OPEN) {
        return 0;
      }

      return messages.filter((message) => !message.seen).length;
    },
  );
