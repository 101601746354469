import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import LockIcon from 'assets/icons/LockIcon.svg';
import ActionIcon from 'assets/icons/chevron.svg';
import {
  CollapseAction,
  CollapseContainer,
  CollapseContent,
  CollapseHeaderLabel,
  CollapseHeader,
} from './Collapse.styled';

interface Props {
  open?: boolean;
  label?: string;
  isLocked?: boolean;
  onAnimationComplete?: () => void;
}

export const Collapse: React.FC<Props> = ({
  children,
  open,
  label,
  onAnimationComplete,
  isLocked,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleHeaderClick = () => {
    if (isLocked) {
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <>
      <CollapseHeader onClick={handleHeaderClick}>
        <CollapseHeaderLabel>{label}</CollapseHeaderLabel>
        <CollapseAction isOpen={isOpen}>
          {isLocked ? <LockIcon /> : <ActionIcon className="chevron" />}
        </CollapseAction>
      </CollapseHeader>
      <AnimatePresence initial={false}>
        {isOpen && (
          <CollapseContainer
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            onAnimationComplete={onAnimationComplete}
          >
            <CollapseContent
              variants={{
                collapsed: { scale: 0.95, translateY: '-100%' },
                open: { scale: 1, translateY: '0' },
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              {children}
            </CollapseContent>
          </CollapseContainer>
        )}
      </AnimatePresence>
    </>
  );
};
