import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalPage } from 'atoms/ModalPage';
import { CurrentValue, ModalContentArea } from 'molecules/SettingItems/SettingsForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserEmail } from 'store/user/selectors';
import { UpdateEmailRequest } from 'services/pttv/api/users/types';
import { PttvError, PttvErrorCode } from 'services/pttv/types';
import { updateEmail } from 'store/user/actions';
import { useMessages } from 'hooks/useMessages';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { FormModal } from '../Form.styled';
import { EmailForm } from '..';

const emailError = [
  PttvErrorCode.EMAIL_INVALID,
  PttvErrorCode.EMAIL_NOT_UNIQUE,
  PttvErrorCode.EMAIL_REQUIRED,
];

interface Props {
  isOpen: boolean;
  onClose: (emailUpdated: boolean) => void;
}

export const EmailFormModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [minHeight, setMinHeight] = useState('unset');
  const email = useAppSelector(getUserEmail);
  const dispatch = useAppDispatch();
  const { errorMessage, successMessage } = useMessages();
  const { t } = useTranslation('Forms');

  const methods = useForm<UpdateEmailRequest>({
    defaultValues: { email: '' },
    shouldFocusError: true,
  });
  const { control, handleSubmit, reset, setError } = methods;

  const contentRef = (node: HTMLElement | null) => {
    if (node !== null) {
      setMinHeight(`calc(${node.getBoundingClientRect().height}px + 9rem)`);
    }
  };

  const onSubmit = async (data: UpdateEmailRequest) => {
    const response = await dispatch(
      updateEmail({
        request: data,
        successMessage,
        errorMessage,
      }),
    );

    if ((response.payload as PttvError).code) {
      if (emailError.includes((response.payload as PttvError).code)) {
        setError('email', {
          type: 'validate',
          message: errorMessage(response.payload as PttvError),
        });
      }
    } else {
      reset();
      onClose(true);
    }
  };

  const title = email ? t('changeEmail.modal.title') : t('addEmail.modal.title');

  return (
    <ModalPage
      title={title}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeActionType={OverlayHeaderActions.Cross}
    >
      <ModalContentArea minHeight={minHeight}>
        <FormProvider {...methods}>
          <FormModal onSubmit={handleSubmit(onSubmit)} ref={contentRef}>
            {!!email && <CurrentValue label={t('changeEmail.currentValue')}>{email}</CurrentValue>}
            <EmailForm control={control} />
          </FormModal>
        </FormProvider>
      </ModalContentArea>
    </ModalPage>
  );
};
