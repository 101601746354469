import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { TextInput, PasswordInput } from 'molecules/Form';
import { useAppSelector } from 'store/hooks';
import { getUserEmail } from 'store/user/selectors';
import { ButtonBar } from 'atoms/ButtonBar';
import { Button } from 'atoms/Button';
import { regexEmail, regexPassword } from 'utils/regex';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { toLowerCase } from 'utils/formFormatters';
import { ProTip } from 'molecules/ProTip';
import { UpdateEmailRequest } from 'services/pttv/api/users/types';
import { Form } from '../Form.styled';

interface Props {
  control: Control<UpdateEmailRequest>;
}

export const EmailForm: React.FC<Props> = ({ control }) => {
  const email = useAppSelector(getUserEmail);
  const { t } = useTranslation(['Forms', 'Messages']);

  const texts = {
    emailLabel: email ? t('Forms:changeEmail.emailLabel') : t('Forms:addEmail.emailLabel'),
    emailPlaceholder: email
      ? t('Forms:changeEmail.emailPlaceholder')
      : t('Forms:addEmail.emailPlaceholder'),
    submitButton: email ? t('Forms:changeEmail.submitButton') : t('Forms:addEmail.submitButton'),
  };

  return (
    <Form>
      <TextInput
        name="email"
        control={control}
        format={toLowerCase}
        rules={{
          required: t('Messages:error.EMAIL_REQUIRED'),
          pattern: {
            value: regexEmail,
            message: t('Messages:error.EMAIL_INVALID'),
          },
        }}
        label={texts.emailLabel}
        placeholder={texts.emailPlaceholder}
      />
      {!email && (
        <>
          <PasswordInput
            name="password"
            control={control}
            rules={{
              required: t('Forms:addEmail.passwordErrorRequired'),
              pattern: {
                value: regexPassword,
                message: t('Forms:addEmail.passwordErrorInvalid'),
              },
            }}
            label={t('Forms:addEmail.passwordLabel')}
            placeholder={t('Forms:addEmail.passwordPlaceholder')}
          />
          <ProTip title={t('Forms:addEmail.passwordTip.title')} showToggle={false}>
            {t('Forms:addEmail.passwordTip.tipsTitle')}
            <ul>
              {(t('Forms:addEmail.passwordTip.tips', { returnObjects: true }) || []).map((tip) => (
                <li key={tip}>{tip}</li>
              ))}
            </ul>
          </ProTip>
        </>
      )}
      <ButtonBar>
        <Button type="submit" size={ButtonSize.Wide} scheme={ButtonSchemes.Secondary}>
          {texts.submitButton}
        </Button>
      </ButtonBar>
    </Form>
  );
};
