import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Check from 'assets/icons/check.svg';
import {
  GameCard as GameCardContainer,
  GameCardAction,
  GameCardAside,
  GameCardDescription,
  GameCardGame,
  GameCardName,
  GameCardStatus,
} from 'atoms/Cards';
import { Pill } from 'atoms/Pill';
import { useDialog } from 'hooks/useDialog';
import { Game } from 'molecules/Game';
import { canJoinLiveContests, getFirstOpenContestByGame } from 'store/contests/selectors';
import { getLiveGameIds, joinedGameContestId } from 'store/games/selectors';
import { useAppSelector } from 'store/hooks';
import { getOpenPropositionCountByGame } from 'store/propositions/selectors';
import { Colors } from 'style/types';
import { formatOrdinals, formatTime } from 'utils/formatters';
import { GameCardType } from '../types';
import { LiveBall } from './GameCard.styled';

interface Props {
  gameId: string;
  sportId: string;
  type: GameCardType;
}

export const GameCard: React.FC<Props> = ({ gameId, sportId, type }) => {
  const game = useAppSelector((state) => state.games[gameId]);
  const sport = useAppSelector((state) => state.sports[sportId]);
  const joinedContestId = useAppSelector(joinedGameContestId(gameId));
  const openPropositionsCount = useAppSelector(getOpenPropositionCountByGame(gameId));
  const canJoinLiveContest = useAppSelector(canJoinLiveContests);
  const liveGameIds = useAppSelector(getLiveGameIds);
  const firstOpenContest = useAppSelector(getFirstOpenContestByGame(gameId));

  const [isLocked, setIsLocked] = useState(false);
  const homeMatch = useRouteMatch({
    path: '/',
    exact: true,
  });
  const { push } = useHistory();
  const { showAlert } = useDialog();
  const { t } = useTranslation('Cards');

  const showDescription = () =>
    (!!game.name || !!game.description) && type !== GameCardType.Pending;
  const showJoinedPill = () => !!joinedContestId && type !== GameCardType.Joined;
  const showNewPropsPill = () => !!openPropositionsCount && type === GameCardType.Joined;
  const showPeriodPill = () => (firstOpenContest?.period || 0) > 1;

  const handleGameCardClick = () => {
    if (type === GameCardType.Pending) {
      showAlert(t('gameCard.pendingMessage.title'), t('gameCard.pendingMessage.description'));
      return;
    }
    if (type === GameCardType.Upcoming) {
      showAlert(t('gameCard.upcomingMessage.title'), t('gameCard.upcomingMessage.description'));
      return;
    }
    if (isLocked) {
      showAlert(t('gameCard.lockedMessage.title'), t('gameCard.lockedMessage.description'));
      return;
    }
    if (joinedContestId) {
      push(`/games/${gameId}/contests/${joinedContestId}`);
      return;
    }
    if (homeMatch) {
      push(`/schedule/${gameId}/room-selection`);
    } else {
      push(`/game/${gameId}/room-selection`);
    }
  };

  useEffect(() => {
    setIsLocked(
      type === GameCardType.Open &&
        !joinedContestId &&
        !canJoinLiveContest &&
        liveGameIds.includes(gameId),
    );
  }, [canJoinLiveContest, liveGameIds]);

  return (
    <GameCardContainer onClick={handleGameCardClick} type={type} isLocked={isLocked}>
      <GameCardName>{game.name}</GameCardName>
      {showDescription() && (
        <GameCardDescription>{game.description || game.name}</GameCardDescription>
      )}
      <GameCardGame>
        <Game
          horizontal={type === GameCardType.Joined}
          sportId={game.sportId}
          homeTeamId={game.homeTeamId}
          awayTeamId={game.awayTeamId}
        />
      </GameCardGame>
      <GameCardAside>
        {showNewPropsPill() && (
          <GameCardStatus>
            <Pill backgroundColor={Colors.Tertriary}>
              {t('gameCard.pills.new', { propositionCount: openPropositionsCount })}
            </Pill>
          </GameCardStatus>
        )}
        <GameCardStatus>
          {sport?.avatarUrl && <img src={sport.avatarUrl} alt={sport.name} />}
          <div>
            {showPeriodPill() ? (
              <Pill withIcon backgroundColor={Colors.Primary}>
                <LiveBall />
                {`${formatOrdinals(firstOpenContest?.period || 0)} ${sport.period}`}
              </Pill>
            ) : (
              formatTime(game.broadcastDate)
            )}
          </div>
        </GameCardStatus>

        {showJoinedPill() && (
          <GameCardStatus>
            <Pill withIcon>
              <Check />
              {t('gameCard.pills.joined')}
            </Pill>
          </GameCardStatus>
        )}
      </GameCardAside>
      <GameCardAction cardType={type} game={game} isLocked={isLocked} />
    </GameCardContainer>
  );
};
