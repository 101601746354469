import React from 'react';
import { getContestHistoryRoom } from 'store/contestHistory/selectors';
import { useAppSelector } from 'store/hooks';
import { LeaderboardMovements } from 'store/leaderboardMovement/types';
import { getUser } from 'store/user/selectors';
import { LeaderboardUser } from './LeaderboardUser';

interface Props {
  contestId: string;
  roomId: string;
}

export const ContestHistoryRoomLeaderboardUser: React.FC<Props> = ({ contestId, roomId }) => {
  const room = useAppSelector(getContestHistoryRoom(contestId, roomId));
  const user = useAppSelector(getUser);
  const roomUser = room?.players.find((player) => player.userId === user.userId);

  if (!room || !roomUser) {
    return null;
  }

  return (
    <LeaderboardUser user={roomUser} movement={LeaderboardMovements.Equal} title={room.name} />
  );
};
