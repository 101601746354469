import React from 'react';
import { PlayerCountContainer, PlayerCountLabel } from './RoomCardPlayerCount.styled';

interface PlayerCountProps {
  count: number;
  label: string;
}

export const RoomCardPlayerCount: React.FC<PlayerCountProps> = ({ count, label }) => (
  <PlayerCountContainer>
    <PlayerCountLabel label={label} />
    {count}
  </PlayerCountContainer>
);
