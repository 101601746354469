import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  cashBetIframeClosed,
  cashBetIframeLoaded,
  cashBetIframeOpened,
} from 'store/cashBet/actions';
import { getCashBetUrlForType } from 'store/cashBet/selectors';
import { CashBetIframeId, CashBetPageType } from 'store/cashBet/types';
import { isUserGuest } from 'store/user/selectors';
import { useDialog } from 'hooks/useDialog';
import { EmailFormModal } from 'organisms/Forms/Modals/EmailFormModal';
import { CashBetStyle, CashBetIframeStyle } from './CashBet.styled';

interface Props {
  backUrl?: string;
  blockGuestUsers?: boolean;
  pageType: Exclude<CashBetPageType, CashBetPageType.AutoLogin>; // Autologin isn't done through this component.
}

export const CashBet: React.FC<Props> = ({ backUrl, pageType, blockGuestUsers }) => {
  const dispatch = useAppDispatch();
  const { showConfirm } = useDialog();
  const { goBack, replace } = useHistory();
  const { t } = useTranslation('CashBet');
  const url = useAppSelector((state) => getCashBetUrlForType(state, pageType));
  const error = useAppSelector((state) => state.cashBet.error);
  const isGuest = useAppSelector(isUserGuest);
  const guestUserError = blockGuestUsers && isGuest;
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  if (url === null) {
    return null;
  }

  // The iframe will break when it's open while an autologin error has occured on the autologin iframe. Therefore don't show this iframe when that error occurs.
  // For the autologin error a dialog will be shown in which the autologin can retry the autologin.
  if (error && error.message === 'autologin failed') {
    return null;
  }

  const onLoad = () => dispatch(cashBetIframeLoaded());

  const onCloseGuestErrorDialog = () => {
    if (backUrl) {
      const decodedUri = decodeURIComponent(backUrl);
      replace(decodedUri);
    } else {
      goBack();
    }
  };

  const onConfirmGuestErrorDialog = () => {
    setIsEmailModalOpen(true);
  };

  const showGuestErrorDialog = () => {
    showConfirm(t('guestErrorDialog.title'), t('guestErrorDialog.description'), {
      closeButtonAction: onCloseGuestErrorDialog,
      closeButtonText: t('guestErrorDialog.closeButton'),
      confirmButtonAction: onConfirmGuestErrorDialog,
      confirmButtonText: t('guestErrorDialog.confirmButton'),
    });
  };

  const onCloseAddEmailModal = (emailUpdated: boolean) => {
    setIsEmailModalOpen(false);
    if (!emailUpdated) {
      showGuestErrorDialog();
    }
  };

  useEffect(() => {
    dispatch(cashBetIframeOpened({ backUrl, pageType }));

    if (guestUserError) {
      showGuestErrorDialog();
    }

    return () => {
      dispatch(cashBetIframeClosed());
    };
  }, []);

  if (guestUserError) {
    return <EmailFormModal isOpen={isEmailModalOpen} onClose={onCloseAddEmailModal} />;
  }

  return (
    <CashBetStyle>
      <CashBetIframeStyle
        id={`${CashBetIframeId}-${pageType}`}
        src={url}
        title="cb-frame"
        frameBorder="0"
        onLoad={onLoad}
      />
    </CashBetStyle>
  );
};
