import styled from 'styled-components';
import { FontFamily, FontSize, FontWeight } from 'style/types';
import { StyledOverlayHeader } from 'molecules/OverlayHeader/OverlayHeader.styled';

interface Props {
  hasLeague: boolean;
  shareInvite: boolean;
}

export const InviteFriendsWrapper = styled.section<Props>`
  display: grid;
  grid-template-rows: repeat(${({ hasLeague }) => (hasLeague ? 4 : 3)}, auto) 1fr ${({
      shareInvite,
    }) => (shareInvite ? 'auto' : '')};
  height: 100%;
  gap: 1rem;
  overflow: scroll;

  > ${StyledOverlayHeader} {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

export const InviteTitle = styled.h1`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.L};
  font-weight: ${FontWeight.Medium};
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

export const SearchForm = styled.form`
  padding: 0 1rem;
`;
