import React from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { PlayerCount } from 'atoms/PlayerCount';
import { useAppSelector } from 'store/hooks';
import { getLeagueByName } from 'store/leagues/selectors';
import { getSportById } from 'store/sports/selectors';
import { getTeamById } from 'store/teams/selectors';
import { LeagueMemberInvited } from 'services/pttv/api/constants';
import { LeagueCardTitle, LeagueCardSportLogo } from 'atoms/Cards/LeagueCard';
import { InviteLeagueCardContainer } from './InviteLeagueCard.styled';

interface Props {
  leagueName: string;
}

export const InviteLeagueCard: React.FC<Props> = ({ leagueName }) => {
  const { push } = useHistory();
  const league = useAppSelector(getLeagueByName(leagueName));
  const sport = useAppSelector(getSportById(league?.affiliatedSportId || ''));
  const team = useAppSelector(getTeamById(league?.affiliatedTeamId || ''));

  if (!league) {
    return null;
  }

  const handleLeagueCardClick = () => {
    push(`/social/${league.name}`);
  };

  return (
    <InviteLeagueCardContainer onClick={handleLeagueCardClick}>
      {!!sport && <LeagueCardSportLogo src={sport.avatarUrl} />}
      <Avatar border size={AvatarSize.XL} src={league.avatarUrl} />

      <LeagueCardTitle
        title={league.name}
        description={team ? `${team.abbreviation} - ${team.cityName}` : league.description}
      />

      <PlayerCount
        count={
          league.members.filter((member) => member.invite === LeagueMemberInvited.ACCEPTED).length
        }
      />
    </InviteLeagueCardContainer>
  );
};
