import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { Room } from 'services/pttv/api/rooms/types';
import { UserMetaState } from 'store/userMeta/types';
import chatLogoUrl from 'assets/images/chat-logo.png';
import { RoomChatMessage } from './types';

export const isMuted = (
  { senderId, timestamp }: RoomChatMessage,
  userMeta: UserMetaState,
): boolean =>
  !!senderId &&
  !!userMeta.mutedUsers[senderId]?.mutedAt &&
  timestamp >= (userMeta?.mutedUsers[senderId]?.mutedAt || 0);

export const getChatMessageType = (room: Room, message: RoomChatMessage): ChatBubbleTypes => {
  if (message.contestId) {
    return ChatBubbleTypes.Winview;
  }
  if (message.senderId === room.user.userId) {
    return ChatBubbleTypes.Right;
  }
  return ChatBubbleTypes.Left;
};

export const getChatAvatarUrl = (avatarUrl = '', type: ChatBubbleTypes): string => {
  if (type === ChatBubbleTypes.Winview) {
    return chatLogoUrl;
  }
  return avatarUrl;
};

export const getChatSenderName = (senderName = '', type: ChatBubbleTypes): string => {
  if (type === ChatBubbleTypes.Winview) {
    return 'WinView';
  }
  return senderName;
};
