import React, { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { JoinRoomCard } from 'molecules/RoomCard';
import { getSortedRoomTypeIdsByContest, getRoomTypeByIds } from 'store/roomTypes/selectors';
import { Contest } from 'store/contests/types';
import {
  selectedRoomTypeIds,
  hasUserTicketForRoom,
  hasUserDollarCentsForRoom,
  canJoinRoomByDollarCents,
  canJoinRoomWithTickets,
} from 'store/roomSelection/selectors';
import { useAppSelector } from 'store/hooks';
import { toggleRoomType } from 'store/roomSelection/actions';
import { useDialog } from 'hooks/useDialog';
import { fetchTicketTypesByRoomTypeIds } from 'store/ticketTypes/actions';
import { getTicketTypeById } from 'store/ticketTypes/selectors';
import { Tickets } from 'atoms/Tickets';
import { NoResultsCard } from 'molecules/NoResultsCard';
import ScheduleIcon from 'assets/icons/ScheduleIcon.svg';

interface Props {
  contest: Contest;
}

export const JoinRoomCardList: React.FC<Props> = ({ contest }) => {
  const { contestId } = contest;
  const store = useStore();
  const dispatch = useDispatch();
  const RoomTypeIds = useAppSelector(getSortedRoomTypeIdsByContest(contestId));
  const selectedRoomTypes = useAppSelector(selectedRoomTypeIds(contestId, RoomTypeIds));
  const roomTypes = useAppSelector(getRoomTypeByIds(RoomTypeIds));
  const { showAlert } = useDialog();

  const { showConfirm } = useDialog();
  const { push } = useHistory();
  const { t } = useTranslation('RoomSelection');

  useEffect(() => {
    if (RoomTypeIds.length) {
      dispatch(fetchTicketTypesByRoomTypeIds(RoomTypeIds));
    }
  }, [RoomTypeIds]);

  const handleRoomTypeSelect = (roomTypeId: string) => {
    if (!selectedRoomTypes[roomTypeId]) {
      if (!canJoinRoomByDollarCents(roomTypeId)(store.getState())) {
        showConfirm(t('guestUserDialog.title'), t('guestUserDialog.description'), {
          closeButtonText: t('guestUserDialog.closeButton'),
          confirmButtonText: t('guestUserDialog.confirmButton'),
          confirmButtonAction: () => {
            push('/profile/settings');
          },
        });
        return;
      }

      if (
        !canJoinRoomWithTickets(contestId, roomTypeId)(store.getState()) &&
        !hasUserDollarCentsForRoom(roomTypeId)(store.getState())
      ) {
        const ticket = getTicketTypeById(roomTypes[roomTypeId].ticketBuyIn || '')(store.getState());

        showAlert(
          t('missingTicketModal.title'),
          <>
            {t('missingTicketModal.message')}
            {ticket && <Tickets>{ticket.displayName}</Tickets>}
          </>,
        );
        return;
      }
    }

    const roomType = roomTypes[roomTypeId];
    const hasTicket = hasUserTicketForRoom(roomTypeId)(store.getState());

    const payload = {
      roomTypeId,
      contestId,
      buyInMethod: {
        dollarCents:
          roomType.dollarCentsBuyIn && roomType.ticketBuyIn && hasTicket
            ? 0
            : roomType.dollarCentsBuyIn || 0,
        ticket: hasTicket ? (roomType.ticketBuyIn as string) : '',
      },
    };

    dispatch(toggleRoomType(payload));
  };

  return (
    <>
      {RoomTypeIds.length === 0 && (
        <NoResultsCard title={t('noRoomTypesAvailabe.title')} icon={<ScheduleIcon />}>
          {t('noRoomTypesAvailabe.description')}
        </NoResultsCard>
      )}
      {RoomTypeIds.map((roomTypeId) => (
        <JoinRoomCard
          key={roomTypeId}
          roomTypeId={roomTypeId}
          contestId={contest.contestId}
          onSelect={() => handleRoomTypeSelect(roomTypeId)}
        />
      ))}
    </>
  );
};
