import React, { ChangeEvent, FormEvent, useState } from 'react';
import { ChatMessageInput } from 'atoms/Chat/MessageInput';
import { ChatMessageSubmit } from 'atoms/Chat/MessageSubmit';
import { StyledMessageForm } from './MessageForm.styled';

interface Props {
  onMessageSubmit: (message: string) => void;
}

export const ChatMessageForm: React.FC<Props> = ({ onMessageSubmit }) => {
  const [message, setMessage] = useState('');

  const handleMessageSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (message) {
      onMessageSubmit(message);
      setMessage('');
    }
  };

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setMessage(e.target.value);
  };
  return (
    <StyledMessageForm onSubmit={handleMessageSubmit}>
      <ChatMessageInput value={message} onChange={handleMessageChange} />
      <ChatMessageSubmit />
    </StyledMessageForm>
  );
};
