import styled from 'styled-components';
import { Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { StyledDivider } from 'atoms/Divider/Divider.styled';
import { pxToRem } from 'utils/pxToRem';

export const GameListContainer = styled.div`
  padding: 0 ${Padding.Default};

  > * + * {
    margin-top: ${Gap.Default};
  }

  & + div {
    margin-top: ${Gap.Default};
  }
`;

export const DateHeader = styled.section`
  background-color: ${Colors.Black};
  color: ${Colors.Secondary};
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.SemiBold};
  position: sticky;
  text-transform: uppercase;
  top: 0;
  z-index: 1;

  ${StyledDivider} + ${GameListContainer} & {
    top: ${pxToRem(50)};
  }
`;
