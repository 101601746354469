import styled from 'styled-components';
import { Gap } from 'style/types';

export const LiveBall = styled.div`
  background-color: red;
  border: 1px solid white;
  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
  margin: 0 ${Gap.XS};
`;
