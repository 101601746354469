import styled from 'styled-components';
import React from 'react';
import CheckIcon from 'assets/icons/checkIcon.svg';
import CrossIcon from 'assets/icons/CrossIcon.svg';
import MinusIcon from 'assets/icons/MinusIcon.svg';
import { pxToRem } from 'utils/pxToRem';
import { BorderRadius, BorderWidth, Colors } from 'style/types';
import { AnswerCode } from 'services/pttv/api/constants';

const propositionResultIcon = (success: boolean, answer: AnswerCode) => {
  if (answer === AnswerCode.UNDECIDED) {
    return <MinusIcon />;
  }
  return success ? <CheckIcon /> : <CrossIcon />;
};

const propositionResultColor = (success: boolean, answer: AnswerCode) => {
  if (answer === AnswerCode.UNDECIDED) {
    return Colors.Black50;
  }
  return success ? Colors.Success : Colors.Error;
};

interface InputProps {
  success: boolean;
  correctAnswer: AnswerCode;
}

interface AttrsProps {
  iconColor: Colors;
}

export const PropositionCardResultIcon = styled.section.attrs<InputProps, AttrsProps>(
  ({ success, correctAnswer }) => ({
    children: propositionResultIcon(success, correctAnswer),
    iconColor: propositionResultColor(success, correctAnswer),
  }),
)<InputProps>`
  align-items: center;
  background-color: ${({ iconColor }) => iconColor};
  color: ${({ iconColor }) => iconColor};
  border-radius: 0 ${BorderRadius.Default};
  display: flex;
  height: ${pxToRem(32)};
  justify-content: center;
  position: absolute;
  right: calc(-${BorderWidth.Large} / 2);
  top: calc(-${BorderWidth.Large} / 2);
  width: ${pxToRem(32)};

  &::after {
    background-color: hsla(0, 0%, 16%, 0.6);
    border-radius: 50%;
    content: '';
    height: ${pxToRem(16)};
    position: absolute;
    width: ${pxToRem(16)};
    z-index: 0;
  }

  > svg {
    width: ${pxToRem(8)};
    z-index: 1;
  }
`;
