import styled from 'styled-components';
import { Gap, Padding } from 'style/types';

export const RoomLeaderboardWrapper = styled.section`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow: auto;
`;

export const RoomLeaderboardContest = styled.section`
  overflow: scroll;

  > * {
    margin-bottom: ${Gap.L};
  }
`;

export const RoomLeaderboardFooter = styled.footer`
  padding: ${Padding.Default};
`;
