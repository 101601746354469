import styled from 'styled-components';
import React from 'react';
import { Colors, Padding } from 'style/types';
import InfoIcon from 'assets/icons/InfoIcon.svg';
import { pxToRem } from 'utils/pxToRem';

export const InfoButton = styled.button.attrs({
  type: 'button',
  children: <InfoIcon />,
})`
  background-color: transparent;
  color: ${Colors.Black40};
  cursor: pointer;
  border: none;
  padding: ${Padding.Default};

  > svg {
    width: ${pxToRem(18)};
  }
`;
