import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'molecules/Form/TextInput';
import { Select } from 'molecules/Form/Select';
import { Button } from 'atoms/Button';
import { useAppSelector } from 'store/hooks';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { getSports } from 'store/sports/selectors';
import { getTeamsGroupedBySport } from 'store/teams/selectors';
import { Team } from 'store/teams/types';
import { LeagueAvatarSelect } from 'molecules/Form/LeagueAvatarSelect';
import { UpdateLeaguePayload } from 'store/leagues/types';
import { regexLeagueName } from 'utils/regex';
import { PttvError } from 'services/pttv/types';
import { useMessages } from 'hooks/useMessages';
import { Form } from './LeagueForm.styled';

interface Props {
  onSubmit: SubmitHandler<UpdateLeaguePayload>;
  defaultValues?: UpdateLeaguePayload;
  buttonText: string;
}

type FieldName =
  | 'name'
  | 'description'
  | 'avatarId'
  | 'affiliatedTeamId'
  | 'affiliatedSportId'
  | '';

export const LeagueForm: React.FC<Props> = ({ onSubmit, defaultValues, buttonText }) => {
  const sports = useAppSelector(getSports);
  const { errorMessage } = useMessages();
  const teams = useAppSelector(getTeamsGroupedBySport);
  const [sportTeams, setSportTeams] = useState<Team[]>([]);
  const [sportLogo, setSportLogo] = useState<string>();
  const [teamLogo, setTeamLogo] = useState<string>();
  const { t } = useTranslation(['LeagueForm', 'Messages']);

  const methods = useForm<UpdateLeaguePayload>({
    defaultValues,
  });
  const { control, handleSubmit, watch, setValue, setError } = methods;

  const selectedSport = watch('affiliatedSportId');
  const selectedTeam = watch('affiliatedTeamId');

  useEffect(() => {
    if (!selectedSport) {
      setSportLogo('');
      setValue('affiliatedTeamId', '');
      return;
    }
    setSportTeams(Object.values(teams[selectedSport] || {}));
    const logo =
      selectedSport && sports[selectedSport] ? sports[selectedSport].avatarUrl : undefined;
    setSportLogo(logo);
    if (selectedTeam) {
      setValue('affiliatedTeamId', teams[selectedSport][selectedTeam]?.teamId || '');
    }
  }, [selectedSport]);

  useEffect(() => {
    if (!selectedSport || !selectedTeam) {
      setTeamLogo('');
      return;
    }
    const logo =
      selectedTeam && teams[selectedSport][selectedTeam]
        ? teams[selectedSport][selectedTeam].logoUrl
        : undefined;
    setTeamLogo(logo);
  }, [selectedTeam, selectedSport]);

  const handleFormSubmit = async (data: UpdateLeaguePayload) => {
    const response = await onSubmit({
      ...data,
      affiliatedTeamId: data.affiliatedTeamId || null,
      affiliatedSportId: data.affiliatedSportId || null,
    });

    if (response === null) {
      return;
    }

    let fieldName: FieldName = '';
    switch (response.code) {
      case 'NAME_INVALID':
        fieldName = 'name';
        break;

      case 'DESCRIPTION_INVALID':
        fieldName = 'description';
        break;

      default:
    }
    if (fieldName !== '') {
      setError(fieldName, {
        type: 'validate',
        message: errorMessage(response as PttvError),
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <LeagueAvatarSelect
          name="avatarId"
          control={control}
          rules={{ required: t('LeagueForm:selectAvatar.requiredError') }}
        />
        <TextInput
          name="name"
          control={control}
          label={t('LeagueForm:inputName.label')}
          disabled={!!defaultValues?.name}
          rules={{
            required: t('LeagueForm:inputName.requiredError'),
            pattern: {
              value: regexLeagueName,
              message: t('Messages:error.NAME_INVALID'),
            },
          }}
        />
        <TextInput
          name="description"
          control={control}
          label={t('LeagueForm:inputDescription.label')}
          rules={{
            required: t('LeagueForm:inputDescription.requiredError'),
            pattern: {
              value: regexLeagueName,
              message: t('Messages:error.DESCRIPTION_INVALID'),
            },
          }}
        />
        <Select
          name="affiliatedSportId"
          control={control}
          label={t('LeagueForm:inputAffiliatedSport.label')}
          logo={sportLogo}
        >
          <option value="">{t('LeagueForm:inputAffiliatedSport.firstOption')}</option>
          {Object.values(sports).map((sport) => (
            <option value={sport.sportId} key={sport.sportId}>
              {sport.abbreviation}
            </option>
          ))}
        </Select>
        <Select
          name="affiliatedTeamId"
          control={control}
          label={t('LeagueForm:inputAffiliatedTeam.label')}
          logo={teamLogo}
          disabled={sportTeams.length === 0}
        >
          <option value="">{t('LeagueForm:inputAffiliatedTeam.firstOption')}</option>
          {sportTeams.map((team) => (
            <option value={team.teamId} key={team.teamId}>
              {team.abbreviation}
            </option>
          ))}
        </Select>
        <ButtonBar>
          <Button size={ButtonSize.Wide} scheme={ButtonSchemes.Secondary} type="submit">
            {buttonText}
          </Button>
        </ButtonBar>
      </Form>
    </FormProvider>
  );
};
