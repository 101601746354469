import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OverlayHeader } from 'molecules/OverlayHeader';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { ContentArea } from 'atoms/ContentArea';
import { LocationErrorMessage } from 'molecules/LocationErrorMessage';
import { CashBet } from 'molecules/CashBet';
import { CashBetPageType } from 'store/cashBet/types';
import { LocationError } from 'services/wrapper/types';
import wrapperLib from 'services/wrapper';
import { DefaultPageWrapper, Content } from './CashBetPageLayout.styled';

interface Props {
  backUrl?: string;
  pageType: Exclude<CashBetPageType, CashBetPageType.AutoLogin>;
  title: string;
}

export const CashBetPageLayout: React.FC<Props> = ({ title, backUrl, pageType }) => {
  const [error, setError] = useState<LocationError | null>();
  const [loading, setLoading] = useState(true);
  const { goBack, push } = useHistory();

  useEffect(() => {
    wrapperLib
      .isLocationServiceEnabled()
      .then(() => {
        wrapperLib.getCurrentCoordinates();
        setError(null);
        setLoading(false);
      })
      .catch((err: LocationError) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const handleCloseClick = () => {
    if (backUrl) {
      push(backUrl);
    } else {
      goBack();
    }
  };

  return (
    <DefaultPageWrapper>
      <OverlayHeader type={OverlayHeaderActions.Chevron} onClose={handleCloseClick}>
        {title}
      </OverlayHeader>
      <Content>
        {error && (
          <ContentArea>
            <LocationErrorMessage error={error} />
          </ContentArea>
        )}

        {!loading && !error && <CashBet backUrl={backUrl} pageType={pageType} blockGuestUsers />}
      </Content>
    </DefaultPageWrapper>
  );
};
