import styled from 'styled-components';
import { BorderWidth, Colors, FontFamily, FontSize, Gap, Padding } from 'style/types';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { pxToRem } from 'utils/pxToRem';

export const FieldWrapper = styled.dl`
  font-family: ${FontFamily.Heading};
  text-align: center;
  text-transform: uppercase;

  > dd,
  > dt {
    margin: 0;
    padding: 0;
  }
`;

export const FieldLabel = styled.dt`
  color: ${Colors.TextSub};
`;

export const FieldValue = styled.dd`
  align-items: center;
  color: ${Colors.Text};
  display: flex;
  font-size: ${FontSize.XXXL};
  gap: ${Gap.S};
  justify-content: center;
`;

export const LeaderBoardUserTitle = styled.header`
  background-color: hsl(190, 21%, 33%);
  color: ${Colors.Text};
  font-size: ${FontSize.L};
  grid-area: header;
  line-height: 1;
  padding: ${Padding.S};
  text-align: center;
`;

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: image;
  height: ${pxToRem(128)};

  ${StyledAvatar} {
    box-shadow: 0 0 0 ${BorderWidth.Large} ${Colors.Black},
      0 0 0 calc(2 * ${BorderWidth.Large}) ${Colors.Black80};
  }
`;

export const LeaderboardUserFooter = styled.footer`
  align-items: center;
  color: ${Colors.Black20};
  display: flex;
  font-family: ${FontFamily.Heading};
  grid-area: payout;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
`;

export const Payout = styled.div`
  color: ${Colors.Text};
  margin-left: ${Padding.S};
  font-size: ${FontSize.XL};

  svg {
    margin-left: ${Padding.S};
    height: ${pxToRem(16)};
  }
`;

export const LeaderBoardUserContainer = styled.article`
  align-items: center;
  background-color: ${Colors.Black};
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas:
    'header header header'
    'rank image score'
    'payout payout payout';

  > ${FieldWrapper}:first-of-type {
    grid-area: rank;
  }

  > ${FieldWrapper}:nth-of-type(2) {
    grid-area: score;
  }
`;
