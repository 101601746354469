import React from 'react';
import { PropositionCard } from 'molecules/PropositionCard';
import { useAppSelector } from 'store/hooks';
import { getPropositionListItems } from 'store/propositions/selectors';
import { PropositionState } from 'services/pttv/api/constants';
import { Divider } from 'atoms/Divider';
import { ContentArea } from 'atoms/ContentArea';
import { PropositionCount } from './PropositionList.styled';

interface Props {
  contestId: string;
  state: PropositionState[];
  title?: string;
}

export const PropositionList: React.FC<Props> = ({ contestId, state, title }) => {
  const propositions = useAppSelector(getPropositionListItems(contestId, state));

  if (propositions.length === 0) {
    return null;
  }
  return (
    <>
      {title && (
        <Divider sticky action={<PropositionCount>{propositions.length}</PropositionCount>}>
          {title}
        </Divider>
      )}
      <ContentArea>
        {propositions.map((proposition) => (
          <PropositionCard proposition={proposition} key={proposition.propositionId} />
        ))}
      </ContentArea>
    </>
  );
};
