import type { PaymentMethod } from 'services/pttv/api/constants';
import { ServerPayloadContest } from 'store/roomSelection/types';

export interface PayOutModel {
  payoutModelId: string;
  name: string;
  prizeDescription: string;
  active: boolean;
  winnerPercentages?: number[];
  inUse?: boolean;
  ticketPerRank?: Record<string, string>;
  isTicketPayoutmodel?: boolean;
  dollarCentsTicketsValue?: number;
}

export interface RoomPlayer {
  userId: string;
  points: number;
  rank: number;
  inviteCode: string;
  dollarCentsWon: number;
  ticketWon: string;
  displayName: string;
  avatarUrl: string;
  hasParticipated?: boolean;
  invested?: number;
  winPercentage?: number;
  subBetWinPercentage?: number;
  oneShotUsed?: boolean;
  streakCount?: number;
  streakBonus?: number;
  sportExperienceRank?: number;
}

export interface RoomTags {
  id: string;
  name: string;
}

export enum RoomTypeType {
  H2H = 'H2H',
  GLOBAL = 'GLOBAL',
  NORMAL = 'NORMAL',
}

export interface RoomType {
  roomTypeId: string;
  name: string;
  shortDescription: string;
  description: string;
  minCapacity: number;
  capacity: number;
  active: boolean;
  isPromoRoom: boolean;
  termsAndConditions: string;
  canJoinWhileContestIsOpen: boolean;
  roomTags: RoomTags[];
  leaderboardEnabled: boolean;
  roomEnabled: boolean;
  maxPerPlayer?: number;
  paidContestsRestriction?: boolean;
  paidContestsJoinedMinimum?: number;
  paidContestsJoinedMaximum?: number;
  paidContestsRestrictionMaximum?: boolean;
  paymentMethods?: PaymentMethod;
  originalId?: string;
  totalPrizePool?: number;
  dollarCentsBuyIn?: number;
  dollarCentsPrizePool?: number;
  dollarCentsPayoutModel?: PayOutModel;
  ticketBuyIn?: string;
  ticketBuyInDollarCentsValue?: number;
  ticketPrizePoolDollarCentsValue?: number;
  ticketPayoutModel?: PayOutModel;
  dollarCentsRake?: number;
  addOverageToPrizePool?: boolean;
  prizePoolOverriden?: boolean;
}

export interface RoomTypeDetails {
  name: string;
  type: RoomTypeType;
  description: string;
  termsAndConditionsHtml: string;
  minCapacity: number;
  capacity: number;
  leaderboardEnabled: boolean;
  isPromoRoom: boolean;
  dollarCentsBuyIn?: number;
  dollarCentsPrizePool?: number;
  dollarCentsPayoutModel?: PayOutModel;
  ticketBuyIn?: string;
  ticketBuyInDollarCentsValue?: number;
  ticketPrizePoolDollarCentsValue?: number;
  ticketPayoutModel?: PayOutModel;
  dollarCentsRake?: number;
  addOverageToPrizePool?: boolean;
  prizePoolOverriden?: boolean;
}

export interface Room {
  roomId: string;
  contestId: string;
  roomTypeId: string;
  name: string;
  capacity: number;
  players: RoomPlayer[];
  roomType: RoomType;
  user: RoomPlayer;
}

export type GetRoomByIdResponse = Room;

export type GetRoomByIdRequest = {
  roomId: string;
};

export interface RoomScoreRoomPlayer {
  userId: string;
  points: number;
  rank: number;
  dollarCentsWon: number;
  ticketWon: string;
  inviteCode: string;
  displayName: string;
  avatarUrl: string;
}

export interface RoomScore {
  roomId: string;
  roomTypeId: string;
  contestId: string;
  players: RoomScoreRoomPlayer[];
}

export type GetRoomScoreResponse = RoomScore;

export type GetRoomScoreRequest = {
  roomId: string;
};

export interface JoinRoomsRequest {
  requests: {
    contestId: string;
    roomTypes: {
      roomTypeId: string;
      count: number;
      paymentMethod: PaymentMethod | undefined;
    }[];
  }[];
  longitude: number | null;
  latitude: number | null;
  geoAccuracy: number | null;
  requestId: number;
}

export interface JoinRoomRequest {
  request: ServerPayloadContest;
  longitude: number | null;
  latitude: number | null;
  geoAccuracy: number | null;
  requestId: number;
}

export interface RoomQueueEntry {
  roomTypeId: string;
  roomQueueId: string;
  paymentMethod: PaymentMethod | undefined;
}

export interface RoomTypeLeaderboardUpdate {
  roomTypeId: string;
  roomTypeLeaderboardData: {
    top: RoomScoreRoomPlayer[];
    user: RoomScoreRoomPlayer;
    totalPlayers: number;
  };
  prizePerRankDollarCents: number[];
}

export interface JoinRoomResponseItem {
  contestId: string;
  points: number;
  invested: number;
  roomQueueEntries: RoomQueueEntry[];
  roomTypeLeaderboardUpdates: RoomTypeLeaderboardUpdate[];
  ticketIdsPerRoomType: Record<string, string>;
}

export type JoinRoomResponse = JoinRoomResponseItem[];

export interface LeaveRoomRequest {
  roomQueueId: string;
  contestId: string;
}

export interface SendMessageRequest {
  text: string;
}

export type GetRoomTypeDetailsRequest = {
  roomTypeId: string;
};

export type GetRoomTypeDetailsResponse = RoomTypeDetails;
