import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
  Padding,
} from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const ProTipContainer = styled(motion.article)`
  border: ${BorderWidth.Default} solid ${Colors.Black70};
  border-radius: ${BorderRadius.Default};
  display: grid;
  padding: ${Padding.S};
  font-size: ${FontSize.XS};
  overflow: hidden;
`;

export const ProTipHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProTipTitle = styled.div`
  font-size: ${FontSize.L};
  font-family: ${FontFamily.Heading};
  font-weight: ${FontWeight.SemiBold};
  display: flex;
  align-items: center;

  svg {
    color: ${Colors.Secondary};
    height: 1.25rem;
    padding-right: ${Padding.XS};
  }
`;

export const CloseButton = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.Black70};
  transition: transform ease 0.3s;
  height: ${pxToRem(20)};
  padding: 0;
  width: ${pxToRem(20)};

  svg {
    color: ${Colors.Text};
    height: ${pxToRem(8)};
  }

  &:hover {
    transform: scale(1.1);
  }
`;
