import styled from 'styled-components';
import { FontSize } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface IconProps {
  src: string;
}

export const BadgeIcon = styled.section<IconProps>`
  background-image: url('${({ src }) => src}');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: ${pxToRem(10)};
  width: ${pxToRem(10)};
`;

export const BadgeNumber = styled.section`
  font-size: ${FontSize.S};
  line-height: 1;
  margin-top: 0.25rem;
  text-align: center;
  width: ${pxToRem(10)};
`;
