import React from 'react';
import { NoResultsCard } from 'molecules/NoResultsCard';
import { NoResultsPageContainer } from './NoResultsPage.styled';

interface Props {
  title: string;
  icon: JSX.Element;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const NoResultsPage: React.FC<Props> = ({
  title,
  icon,
  buttonText,
  onButtonClick,
  children,
}) => (
  <NoResultsPageContainer>
    <NoResultsCard title={title} icon={icon} buttonText={buttonText} onButtonClick={onButtonClick}>
      {children}
    </NoResultsCard>
  </NoResultsPageContainer>
);
