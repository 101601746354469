import styled from 'styled-components';
import { BorderWidth, Colors, FontFamily, FontSize, Gap, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const FooterWrapper = styled.footer`
  align-items: center;
  border-top: ${BorderWidth.Default} solid ${Colors.Black90};
  display: grid;
  gap: ${Gap.L};
  grid-template-columns: 1fr auto;
  padding: ${Padding.L} ${Padding.Default} ${Padding.Default};
`;

export const CostContainer = styled.div`
  align-items: center;
  color: ${Colors.TextSub};
  display: inline-flex;
  font-family: ${FontFamily.Heading};
  justify-content: space-between;
  min-width: ${pxToRem(128)};
`;

export const Cost = styled.span`
  align-items: center;
  color: ${Colors.Secondary};
  display: inline-flex;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XL};
  margin-left: ${Gap.S};
  letter-spacing: 0.7px;

  > svg {
    max-height: ${pxToRem(20)};
    margin-right: ${pxToRem(4)};
  }
`;
