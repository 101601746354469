import styled from 'styled-components';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';
import { Padding } from 'style/types';

export const Form = styled.form`
  padding: ${Padding.Default};

  > ${ButtonBarContainer} {
    padding: ${Padding.L} 0 0;
  }
`;
