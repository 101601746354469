import styled from 'styled-components';
import { FontSize } from 'style/types';
import { ChatBubbleTypes } from '../Bubble/Bubble.theme';

interface Props {
  type: ChatBubbleTypes;
}

export const ChatSender = styled.section<Props>`
  color: ${({ theme, type }) => theme.chat.sender[type].color};
  font-size: ${FontSize.S};
  font-weight: ${({ theme, type }) => theme.chat.sender[type].fontWeight};
`;
