import styled from 'styled-components';
import { Padding } from 'style/types';

interface Props {
  isLocked: boolean;
}

export const RoomCardAside = styled.footer<Props>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  grid-area: aside;
  justify-content: space-between;
  opacity: ${({ isLocked }) => (isLocked ? 0.35 : 1)};
  padding: 0 ${Padding.Default} ${Padding.Default} 0;

  > button {
    padding-right: 0;
  }
`;
