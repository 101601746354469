import styled from 'styled-components';
import React from 'react';
import { Colors, FontFamily, FontSize, FontWeight, Gap } from 'style/types';
import PlayersIcon from 'assets/icons/PlayersIcon.svg';
import { pxToRem } from 'utils/pxToRem';

export const PlayerCountContainer = styled.div`
  color: ${Colors.Secondary};
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.Medium};
  line-height: 1.2;
  text-align: center;
`;

interface Props {
  label: string;
}

export const PlayerCountLabel = styled.section.attrs<Props>(({ label }) => ({
  children: (
    <>
      {label}
      <PlayersIcon />
    </>
  ),
}))<Props>`
  align-items: center;
  color: ${Colors.TextSub};
  display: flex;
  font-size: ${FontSize.MS};
  justify-content: center;
  text-transform: uppercase;

  > svg {
    color: ${Colors.Secondary};
    height: ${pxToRem(14)};
    margin-left: ${Gap.XXS};
    margin-bottom: ${Gap.XXS};
    width: ${pxToRem(14)};
  }
`;
