import styled from 'styled-components';
import { Colors, FontSize, FontWeight } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const ChatMessageInput = styled.input.attrs({
  type: 'text',
})`
  background-color: ${Colors.Black90};
  border-radius: 9999px;
  border: 1px solid ${Colors.Black40};
  box-sizing: border-box;
  color: ${Colors.Text};
  font-size: ${FontSize.M};
  font-weight: ${FontWeight.Medium};
  height: ${pxToRem(40)};
  letter-spacing: 0.5px;
  padding: 0 50px 0 20px;
  width: 100%;
`;
