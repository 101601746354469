import React from 'react';
import { useHistory } from 'react-router-dom';
import { OverlayHeader } from 'molecules/OverlayHeader';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { DefaultPageWrapper, Content } from './DefaultPageLayout.styled';

interface Props {
  title: string;
  onClose?: () => void;
}

export const DefaultPageLayout: React.FC<Props> = ({ title, onClose, children }) => {
  const { goBack } = useHistory();

  return (
    <DefaultPageWrapper>
      <OverlayHeader type={OverlayHeaderActions.Chevron} onClose={onClose || goBack}>
        {title}
      </OverlayHeader>
      <Content>{children}</Content>
    </DefaultPageWrapper>
  );
};
