import React from 'react';
import {
  RoomCard,
  RoomCardContent,
  RoomCardHeader,
  RoomCardPromoRibbon,
} from 'atoms/Cards/RoomCard';
import { useAppSelector } from 'store/hooks';
import { RoomCardPersonalRank } from 'atoms/Cards/RoomCard/RoomCardPersonalRank';
import { PlayerCount } from 'atoms/PlayerCount';
import { getUserByContestIdAndRoomTypeId } from 'store/contestInfo/selectors';
import { getLeaderboardUserMovement } from 'store/leaderboardMovement/selectors';
import { getRoomTypeById } from 'store/roomTypes/selectors';

interface Props {
  onSelect: (roomId: string) => void;
  roomTypeId: string;
  contestId: string;
}

export const OpenContestRoomTypeCard: React.FC<Props> = ({ roomTypeId, contestId, onSelect }) => {
  const user = useAppSelector(getUserByContestIdAndRoomTypeId(contestId, roomTypeId));
  const movement = useAppSelector(getLeaderboardUserMovement(contestId, roomTypeId));
  const roomType = useAppSelector(getRoomTypeById(roomTypeId));

  const handleRoomClick = () => {
    onSelect(roomTypeId);
  };

  return (
    <RoomCard onClick={handleRoomClick}>
      <RoomCardHeader action={<PlayerCount count={roomType?.capacity || 0} />} isGlobal>
        {roomType?.name || ''}
      </RoomCardHeader>
      {roomType.isPromoRoom && <RoomCardPromoRibbon>Featured</RoomCardPromoRibbon>}
      <RoomCardContent noRightPadding>
        <RoomCardPersonalRank
          dollarCents={user?.dollarCentsWon || 0}
          tickets={!!user?.ticketWon}
          rank={user?.rank || 0}
          movement={movement}
        />
      </RoomCardContent>
    </RoomCard>
  );
};
