import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell } from 'atoms/Table';
import { Avatar } from 'atoms/Avatar';
import { formatNumber } from 'utils/formatters';
import { AvatarSize } from 'atoms/Avatar/types';
import { LeagueLeaderboardEntry } from 'services/pttv/api/leagues/types';
import { FriendModal } from 'organisms/FriendRequestModal/FriendModal';
import { useAppDispatch } from 'store/hooks';
import { requestFetchPlayer } from 'store/players/actions';
import { LeaderboardRow, PlayerCell, LeaderboardCell } from '../LeaderboardListItem.styled';

interface Props {
  player: LeagueLeaderboardEntry;
  rank: number;
  isUser: boolean;
}

export const LeagueLeaderboardListItem: React.FC<Props> = ({ player, isUser, rank }) => {
  const { t } = useTranslation('Leaderboard');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestFetchPlayer(player.userId));
  }, []);

  const showFriendInfo = () => {
    setIsModalOpen(true);
  };

  const hideFriendInfo = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <LeaderboardRow isUser={isUser} onClick={showFriendInfo}>
        <LeaderboardCell align="center" isUser={isUser}>
          {rank}
        </LeaderboardCell>
        <TableCell>
          <PlayerCell>
            <Avatar src={player.avatarUrl} size={AvatarSize.S} />
            {isUser ? 'You' : player.displayName}
          </PlayerCell>
        </TableCell>
        <TableCell>{formatNumber(player.points)}</TableCell>
      </LeaderboardRow>
      <FriendModal
        userId={player.userId}
        title={t('playerModal.title')}
        isOpen={isModalOpen}
        handleCloseButtonClick={hideFriendInfo}
        handleSelectButtonClick={hideFriendInfo}
        reportPlayerCallback={hideFriendInfo}
      />
    </>
  );
};
