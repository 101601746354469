import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  GameCard as GameCardContainer,
  GameCardAction,
  GameCardAside,
  GameCardDescription,
  GameCardGame,
  GameCardName,
  GameCardStatus,
} from 'atoms/Cards';
import { Pill } from 'atoms/Pill';
import { Game } from 'molecules/Game';
import { GameCardType } from 'molecules/GameCard/types';
import { GameWithCancelledStatus } from 'store/contestHistory/types';
import { useAppSelector } from 'store/hooks';
import { Colors } from 'style/types';
import { formatTime } from 'utils/formatters';
import { getSportById } from 'store/sports/selectors';

interface Props {
  game: GameWithCancelledStatus;
}

export const ContestHistoryGameCard: React.FC<Props> = ({ game }) => {
  const sport = useAppSelector(getSportById(game.sportId));
  const { push } = useHistory();
  const { t } = useTranslation('Cards');

  const showDescription = () => !!game.name || !!game.description;
  const showCancelledPill = () => game.cancelled;
  const showAction = () => !game.cancelled;

  const handleGameCardClick = () => {
    if (!showAction()) {
      return;
    }

    push(`/profile/contest-history/game/${game.gameId}`);
  };

  return (
    <GameCardContainer onClick={handleGameCardClick} type={GameCardType.Other}>
      <GameCardName>{game.name}</GameCardName>
      {showDescription() && (
        <GameCardDescription>{game.description || game.name}</GameCardDescription>
      )}
      <GameCardGame>
        <Game sportId={game.sportId} homeTeamId={game.homeTeamId} awayTeamId={game.awayTeamId} />
      </GameCardGame>
      <GameCardAside>
        {showCancelledPill() && (
          <GameCardStatus>
            <Pill backgroundColor={Colors.Error}>{t('gameCard.pills.cancelled')}</Pill>
          </GameCardStatus>
        )}

        <GameCardStatus>
          {sport?.avatarUrl && <img src={sport.avatarUrl} alt={sport.name} />}
          <div>{formatTime(game.broadcastDate)}</div>
        </GameCardStatus>
      </GameCardAside>
      {!game.cancelled && <GameCardAction game={game} cardType={GameCardType.Other} />}
    </GameCardContainer>
  );
};
