import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { ShareDialog } from 'molecules/ShareDialog';
import { useDialog } from 'hooks/useDialog';
import {
  ClipboardUrlButton,
  ShareInviteTitle,
  ShareInviteUrl,
  ShareInviteWrapper,
} from './ShareInvite.styled';

interface Props {
  shareUrl: string;
}

export const ShareInvite: React.FC<Props> = ({ shareUrl }) => {
  const inviteUrl = useRef(null);
  const { t } = useTranslation('InviteFriend');
  const { showAlert } = useDialog();

  const saveToClipboard = async () => {
    if (inviteUrl.current) {
      (inviteUrl.current as HTMLInputElement).select();
      (inviteUrl.current as HTMLInputElement).setSelectionRange(0, 999999);
    }
    try {
      await navigator.clipboard.writeText(shareUrl);
      showAlert(
        t('shareInvite.clipBoardDialog.successTitle'),
        t('shareInvite.clipBoardDialog.successDescription'),
      );
    } catch (e) {
      showAlert(
        t('shareInvite.clipBoardDialog.failTitle'),
        t('shareInvite.clipBoardDialog.failDescription'),
      );
    }
  };

  return (
    <ShareInviteWrapper>
      <ShareInviteTitle>{t('shareInvite.title')}</ShareInviteTitle>
      <ShareInviteUrl>
        <ClipboardUrlButton onClick={saveToClipboard} />
        <input ref={inviteUrl} value={shareUrl} />
      </ShareInviteUrl>
      <ShareDialog
        shareButton={(text, callback) => (
          <Button scheme={ButtonSchemes.DarkGrey} size={ButtonSize.Small} onClick={callback}>
            {text}
          </Button>
        )}
        title={t('inviteFriend.title')}
        shareMessage={{
          url: shareUrl,
          text: t('shareInvite.shareText'),
        }}
      />
    </ShareInviteWrapper>
  );
};
