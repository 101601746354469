import styled, { css } from 'styled-components';
import React from 'react';
import SearchIcon from 'assets/icons/SearchIcon.svg';
import CrossIcon from 'assets/icons/CrossIcon.svg';
import { pxToRem } from 'utils/pxToRem';
import { Input } from 'atoms/Form';
import { BorderRadius, BorderWidth, Colors } from 'style/types';

export const SearchWrapper = styled.section`
  position: relative;
`;

interface SearchInputWrapperProps {
  withButton: boolean;
}

export const SearchButton = styled.button.attrs({
  type: 'submit',
  children: <SearchIcon />,
})`
  align-items: center;
  background-color: ${Colors.ActiveBorder};
  border: ${BorderWidth.Large} solid ${Colors.ActiveBorder};
  border-radius: 0 ${BorderRadius.Default} ${BorderRadius.Default} 0;
  color: ${Colors.ActiveBackground};
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: ${pxToRem(56)};

  > svg {
    height: ${pxToRem(24)};
    width: ${pxToRem(24)};
  }

  &:disabled {
    background-color: ${Colors.ActiveBackground};
    color: ${Colors.ActiveBorder};
  }
`;

export const ClearButton = styled.button.attrs({
  type: 'button',
  children: <CrossIcon />,
})`
  align-items: center;
  background-color: hsl(226, 18%, 53%);
  border: none;
  border-radius: 50%;
  display: flex;
  height: ${pxToRem(24)};
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: ${pxToRem(16)};
  top: 50%;
  transform: translateY(-50%);
  width: ${pxToRem(24)};

  > svg {
    color: ${Colors.ActiveBackground};
    width: ${pxToRem(10)};
  }
`;

export const SearchInputWrapper = styled.section<SearchInputWrapperProps>`
  display: flex;

  ${Input} {
    width: 100%;
  }

  ${({ withButton }) =>
    withButton &&
    css`
      ${ClearButton} {
        right: ${pxToRem(70)};
      }
    `}
`;
