import styled from 'styled-components';
import { Colors, FontSize, Padding } from 'style/types';

export const GameCardDescription = styled.p`
  border-top: 1px solid ${Colors.Black50};
  color: ${Colors.TextSub};
  font-size: ${FontSize.S};
  grid-area: description;
  margin: 0;
  padding-top: ${Padding.XS};
  margin-left: 1rem;
`;
