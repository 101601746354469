import styled from 'styled-components';
import { BorderWidth, Colors, Padding } from 'style/types';
import { FieldGroup } from 'atoms/Form/FieldGroup';
import { Button } from 'atoms/Button';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { pxToRem } from 'utils/pxToRem';
import { ErrorMessage } from 'atoms/Form';

interface Props {
  hasError?: boolean;
}

export const AvatarFieldGroup = styled(FieldGroup)<Props>`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: ${({ hasError }) => (hasError ? 'auto auto' : 'auto')};
  height: ${pxToRem(120)};
  justify-items: start;

  ${StyledAvatar} {
    border-width: ${BorderWidth.XL};
    border-style: solid;
    border-color: ${({ hasError }) => (hasError ? Colors.Error : Colors.Primary)};
    grid-area: 1/2/2/3;
  }

  ${Button} {
    grid-area: 1/3/2/4;
    margin-left: ${Padding.Default};
  }

  ${ErrorMessage} {
    grid-area: 2/1/3/4;
    justify-self: center;
  }
`;

export const ModalContent = styled.section`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  padding: ${Padding.Default};
`;

export const AvatarList = styled.section`
  align-content: start;
  display: grid;
  gap: 1rem 0;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-content: center;
`;

export const AvatarRadio = styled.section`
  display: flex;
  justify-content: center;

  input {
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }

  input:checked + label > picture {
    border: ${BorderWidth.XL} solid ${Colors.Primary};
  }
`;
