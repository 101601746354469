import styled from 'styled-components';
import { Colors, FontSize } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { GameCardStatus } from './GameCardStatus';

export const GameCardAside = styled.div`
  align-self: stretch;
  align-items: center;
  border-right: 1px solid ${Colors.Black50};
  color: ${Colors.TextSub};
  display: flex;
  flex-direction: column;
  font-size: ${FontSize.MS};
  justify-content: center;
  grid-area: time;
  padding: 0 1.5rem;
  white-space: nowrap;

  ${GameCardStatus} > img {
    filter: brightness(0%) invert(0.7);
    width: ${pxToRem(24)};
  }
`;
