import React, { ChangeEvent, KeyboardEvent, PropsWithChildren } from 'react';
import {
  Path,
  PathValue,
  UnpackNestedValue,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { Input, ErrorMessage } from 'atoms/Form';
import { ClearButton, SearchWrapper, SearchInputWrapper, SearchButton } from './SearchInput.styled';

interface Props<T> extends UseControllerProps<T> {
  placeholder?: string;
  disabled?: boolean;
  withButton?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchInput = <T extends Record<string, any>>({
  name,
  control,
  placeholder = '',
  defaultValue,
  rules,
  disabled = false,
  withButton = true,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const { setValue, setFocus } = useFormContext<T>();

  const handleClearButtonClick = () => {
    setValue(name, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
    setFocus(name);
  };

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value.trim() as UnpackNestedValue<PathValue<T, Path<T>>>);
    onBlur();
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setValue(
        name,
        ((event?.target as HTMLInputElement).value || '').trim() as UnpackNestedValue<
          PathValue<T, Path<T>>
        >,
      );
    }
  };

  return (
    <SearchWrapper>
      <SearchInputWrapper withButton={withButton}>
        <Input
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={handleOnBlur}
          onKeyPress={handleKeyPress}
          value={value}
          name={fieldName}
          ref={ref}
          disabled={disabled}
          hasError={!!error}
        />
        {withButton && <SearchButton type="submit" disabled={!value} />}
        {!!value && <ClearButton onClick={handleClearButtonClick} />}
      </SearchInputWrapper>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </SearchWrapper>
  );
};
