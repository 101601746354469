import styled from 'styled-components';
import { BorderRadius, Colors, Gap } from 'style/types';

export const RoomCard = styled.article.attrs({
  role: 'button',
})`
  background-color: ${Colors.Black90};
  border-radius: ${BorderRadius.Default};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas: 'header header' 'banner banner' 'ribbon aside' 'content aside';
  overflow: hidden;
  position: relative;

  + article {
    margin-top: ${Gap.ML};
  }
`;
