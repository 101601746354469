import React, { useState, useLayoutEffect, useRef } from 'react';
import { ModalPage } from 'atoms/ModalPage';
import { ChatMessageForm } from 'molecules/Chat/MessageForm';
import { LastMessageButton } from 'molecules/Chat/LastMessageButton';
import { ChatList } from 'organisms/ChatList';
import {
  getChatMessageListByLeague,
  getUnseenMessagesCount,
  getLastChatMessage,
} from 'store/leagueChat/selectors';
import { setSeenChatMessagesByLeague, sendLeagueChatMessage } from 'store/leagueChat/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ChatModalContent, ChatModalContentArea, ChatModalFooter } from '../ChatModal.styled';

interface Props {
  leagueName: string;
  title: string;
}

export const LeagueChatModal: React.FC<Props> = ({ leagueName, title }) => {
  const chatListRef = useRef(null);
  const messages = useAppSelector(getChatMessageListByLeague(leagueName));
  const lastMessage = useAppSelector(getLastChatMessage(leagueName));
  const unseenCount = useAppSelector(getUnseenMessagesCount(leagueName));
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleChatButtonClick = () => {
    setIsOpen(true);
    dispatch(setSeenChatMessagesByLeague(leagueName));
  };

  const handleMessageSubmit = (text: string) => {
    dispatch(sendLeagueChatMessage({ leagueName, text }));
  };

  const scrollToBottom = () => {
    if (chatListRef.current) {
      (chatListRef.current as HTMLDivElement).scrollTop = 999999;
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages, chatListRef]);

  // Needed for initial scroll to bottom
  setTimeout(scrollToBottom, 250);

  return (
    <>
      <LastMessageButton
        lastMessage={lastMessage}
        unseenCount={unseenCount}
        onClick={handleChatButtonClick}
      />
      <ModalPage title={title} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ChatModalContentArea>
          <ChatModalContent ref={chatListRef}>
            <ChatList messages={messages} />
          </ChatModalContent>
          <ChatModalFooter>
            <ChatMessageForm onMessageSubmit={handleMessageSubmit} />
          </ChatModalFooter>
        </ChatModalContentArea>
      </ModalPage>
    </>
  );
};
