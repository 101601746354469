import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Colors, FontSize, FontWeight, Gap } from 'style/types';

export const StyledPlayerResult = styled(motion.section).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Gap.XXXL};
  justify-items: center;
`;

export const Title = styled.h4`
  color: ${Colors.Black30};
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
`;

export const DisplayName = styled.div`
  font-weight: ${FontWeight.SemiBold};
`;
