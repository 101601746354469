import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, Gap, Padding } from 'style/types';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';
import { pxToRem } from 'utils/pxToRem';
import { ProTipContainer } from 'molecules/ProTip/ProTip.styled';

export const Form = styled.form`
  padding: ${Padding.XXL};

  ${ButtonBarContainer} {
    margin-top: ${Gap.XL};
  }

  ${ProTipContainer} {
    margin-top: 1rem;
  }
`;

export const FormModal = styled.article`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};
  flex: 1;
  transform: translateY(-${pxToRem(68)});
`;
