import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'atoms/Divider';
import { GameCard } from 'molecules/GameCard';
import { GameCardType } from 'molecules/GameCard/types';
import { GroupedByDate } from 'store/games/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { NoResultsCard } from 'molecules/NoResultsCard';
import ScheduleIcon from 'assets/icons/ScheduleIcon.svg';
import { DateHeader, GameListContainer } from './GameList.styled';

type Props = {
  selector: (state: RootState) => GroupedByDate;
  type: GameCardType;
  label?: string;
  showNoResultMessage?: boolean;
};

export const GameList: React.FC<Props> = ({ selector, type, label, showNoResultMessage }) => {
  const games = useAppSelector(selector);
  const { t } = useTranslation('SchedulePage');

  return (
    <>
      {label && games.length > 0 && <Divider sticky>{label}</Divider>}
      {games.length ? (
        games.map(([date, gameList]) => (
          <GameListContainer key={date}>
            <DateHeader>{date}</DateHeader>
            {gameList.map(({ gameId, sportId }) => (
              <GameCard key={gameId} type={type} gameId={gameId} sportId={sportId} />
            ))}
          </GameListContainer>
        ))
      ) : (
        <>
          {showNoResultMessage && (
            <GameListContainer>
              <NoResultsCard title={t('gameList.noResult.title')} icon={<ScheduleIcon />}>
                {t('gameList.noResult.description')}
              </NoResultsCard>
            </GameListContainer>
          )}
        </>
      )}
    </>
  );
};
