import React from 'react';
import { LeaderboardMovements } from 'store/leaderboardMovement/types';
import MovementIcon from 'assets/icons/MovementIcon.svg';
import { StyledMovement } from './Movement.styled';

interface Props {
  direction: LeaderboardMovements;
}

export const Movement: React.FC<Props> = ({ direction }) => (
  <>
    {direction === LeaderboardMovements.Equal ? null : (
      <StyledMovement direction={direction}>
        <MovementIcon />
      </StyledMovement>
    )}
  </>
);
