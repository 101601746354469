import React, { PropsWithChildren, ReactElement } from 'react';
import { Avatar } from 'atoms/Avatar';
import { Pill } from 'atoms/Pill';
import { Colors } from 'style/types';
import { AvatarSize } from 'atoms/Avatar/types';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { StyledLastMessageButton, LastMessage, LastMessageIcon } from './LastMessageButton.styled';

interface LastMessage {
  type: ChatBubbleTypes;
  avatarUrl: string;
  message: string;
}

interface Props<T> {
  lastMessage: T;
  unseenCount: number;
  onClick: () => void;
}

export const LastMessageButton = <T extends LastMessage | null>({
  lastMessage,
  unseenCount,
  onClick,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  if (!lastMessage) {
    return null;
  }

  const handleButtonClick = () => {
    onClick();
  };

  return (
    <StyledLastMessageButton messageType={lastMessage.type} onClick={handleButtonClick}>
      <LastMessage messageType={lastMessage.type}>
        <Avatar size={AvatarSize.S} src={lastMessage.avatarUrl} />
        <span>{lastMessage.message}</span>
      </LastMessage>
      <LastMessageIcon>
        {unseenCount ? <Pill backgroundColor={Colors.Tertriary}>{unseenCount}</Pill> : undefined}
      </LastMessageIcon>
    </StyledLastMessageButton>
  );
};
